import React, { useState } from "react";
import { Image } from "react-bootstrap";

// internal
import {
  HighlightShapeThree,
  StartUpFaqOne,
  StartUpFaqThree,
  StartUpFaqTwo,
} from "svg/index";
import shape from "assets/img/faq/faq-shape-1.png";
import SingleFaq from "./single-faq";

// single nav item
function NavItem({ active, id, icon, title, handleTabClick }) {
  const handleClick = () => {
    handleTabClick(id); // Call the parent component function to update the active state
  };

  return (
    <button
      className={`nav-link ${active ? "active" : ""}`}
      onClick={handleClick} // Add onClick event handler
      // Rest of the code...
    >
      <span>{icon}</span>
      {title}
    </button>
  );
}

// tab contents
function TabContent({ active, id, accordion_items }) {
  return (
    <div
      className={`tab-pane fade ${active ? "show active" : ""}`}
      id={id}
      role="tabpanel"
      aria-labelledby={`${id}-tab`}
    >
      <div className="accordion" id={`${id}_accordion`}>
        {accordion_items.map((item, i) => (
          <SingleFaq
            key={i}
            item={{
              ...item,
              parent: `${id}_accordion`,
            }}
          />
        ))}
      </div>
    </div>
  );
}

const StartUpFaqs = ({ element_faq = false }) => {
  const [activeTab, setActiveTab] = useState("general");

  const handleTabClick = (tabId) => {
    console.log({ tabId });
    setActiveTab(tabId);
  };
  return (
    <>
      <section
        className={`faq__area  py-5 z-index-1 pb-${
          element_faq ? "105" : "120"
        }`}
      >
        {!element_faq && (
          <div className="faq__shape">
            <Image className="faq__shape-1" src={shape} alt="shape" />
          </div>
        )}
        <div className="container">
          <div className="row">
            <div className="col-xxl-5 col-xl-5 col-lg-6">
              <div className="faq__wrapper">
                <div className="section__title-wrapper-7 mb-60">
                  <span className="section__title-pre-7">
                    {element_faq ? "Tab Accordion" : "Common Questions"}
                  </span>
                  {!element_faq && (
                    <h3 className="section__title-7">
                      Frequently <br /> asked{" "}
                      <span className="section__title-7-highlight">
                        questions
                        <HighlightShapeThree />
                      </span>
                    </h3>
                  )}
                  {element_faq && (
                    <h3 className="section__title-7">
                      Accordion <br /> with{" "}
                      <span className="section__title-7-highlight">
                        rounded tab
                        <HighlightShapeThree />
                      </span>
                    </h3>
                  )}
                </div>

                <div className="faq__tab tp-tab pr-200">
                  <nav>
                    <div
                      className="nav nav-tabs flex-column"
                      id="nav-tab"
                      role="tablist"
                    >
                      <NavItem
                        active={activeTab === "general"}
                        id="general"
                        icon={<StartUpFaqOne />}
                        title="General Questions"
                        handleTabClick={handleTabClick}
                      />
                      <NavItem
                        active={activeTab === "community"}
                        id="community"
                        icon={<StartUpFaqOne />}
                        title="Community Questions"
                        handleTabClick={handleTabClick}
                      />
                      {/* <NavItem
                        active={activeTab === "community"}
                        id="community"
                        icon={<StartUpFaqTwo />}
                        title="Community"
                        handleTabClick={handleTabClick}
                      />
                      <NavItem
                        active={activeTab === "support"}
                        id="support"
                        icon={<StartUpFaqThree />}
                        title="Support"
                        handleTabClick={handleTabClick}
                      /> */}
                    </div>
                  </nav>
                </div>
              </div>
            </div>
            <div className="col-xxl-7 col-xl-7 col-lg-6">
              <div className="faq__tab-content tp-accordion">
                <div className="tab-content" id="nav-tabContent">
                  <TabContent
                    active={activeTab === "general"}
                    id="nav-general"
                    accordion_items={[
                      {
                        id: "One",
                        show: true,
                        title: "What are the 3D services we provide?",
                        desc: "We provide 3D services like 3D modeling, texturing, rigging, animation, Product Presentation and many more. We have a team of 3D artists who are experts in their field and can create amazing 3D art for your project. Whether you need a 3D model for a video game, a movie or for any other purpose, we can create it for you. We can also texture and animate the model so that it looks exactly like you want it to.",
                      },
                      {
                        id: "Two",
                        title:
                          "Digital marketing services we can help you with?",
                        desc: "We offer a variety of digital marketing services to help you reach your target audience and achieve your business goals. Our services include search media optimization (SMO), pay-per-click (PPC), content marketing. We can tailor our services to your specific needs and budget. Our goal is to maintain your brand's superiority at all times.",
                      },
                      {
                        id: "Three",
                        title: "How do we guarantee the quality of work?",
                        desc: "There are a few ways that we guarantee the quality of our work. The first is by having a strong team of professionals who are experts in their field. We also have a quality control department that reviews each project before it is completed. Lastly, we offer a satisfaction guarantee to our clients. If you are not happy with the final product, we will make it right.",
                      },
                      {
                        id: "Four",
                        title: "What is the goal of Rionick?",
                        desc: "The goal of Rionick is to help businesses and individuals succeed in the digital world. We offer a variety of services that can help you reach your goals, including 3D and 2D design, NFT marketing, and digital marketing. We also offer a variety of tools and resources that can help you succeed in the digital world.",
                      },
                    ]}
                  />
                  <TabContent
                    active={activeTab === "community"}
                    id="nav-community"
                    accordion_items={[
                      {
                        id: "Five",
                        show: true,
                        title:
                          "How much do you charge for graphic designing services?",
                        desc: "Our price for the graphic design depends on the expectations and the complexity of the requirements for your business. It varies from business to business. To know more about our graphic design pricing options, you can drop us a message at any time. We provide affordable graphic design services to make your business stand out.",
                      },
                      {
                        id: "Six",
                        title: "How long does it take to build a website?",
                        desc: "The time frame needed to develop a website depends on size, the type of website required, and the expertise of the team providing web development services. We have built websites for various kinds of businesses and industries. We would be more than happy to give you an estimated development timeline based on your specifications.",
                      },
                      // {
                      //   id: "Seven",
                      //   title:
                      //     " Does my business really need digital marketing?",
                      //   desc: "A startup or start-up is started by individual founders or entrepreneurs to search for a repeatable and scalable business model. A startup or start-up is started by individual founders",
                      // },
                      // {
                      //   id: "Eight",
                      //   title: "Do you offer startup packages?",
                      //   desc: "A startup or start-up is started by individual founders or entrepreneurs to search for a repeatable and scalable business model. A startup or start-up is started by individual founders",
                      // },
                    ]}
                  />
                  <TabContent
                    active={activeTab === "support"}
                    id="nav-support"
                    accordion_items={[
                      {
                        id: "Nine",
                        show: true,
                        title: "Who is the typical Curious client?",
                        desc: "A startup or start-up is started by individual founders or entrepreneurs to search for a repeatable and scalable business model. A startup or start-up is started by individual founders",
                      },
                      {
                        id: "Ten",
                        title: "What are your typical timelines?",
                        desc: "A startup or start-up is started by individual founders or entrepreneurs to search for a repeatable and scalable business model. A startup or start-up is started by individual founders",
                      },
                      {
                        id: "Eleven",
                        title: "Do you offer flexible payment terms?",
                        desc: "A startup or start-up is started by individual founders or entrepreneurs to search for a repeatable and scalable business model. A startup or start-up is started by individual founders",
                      },
                      {
                        id: "Twelve",
                        title: "Do you trade services for equity?",
                        desc: "A startup or start-up is started by individual founders or entrepreneurs to search for a repeatable and scalable business model. A startup or start-up is started by individual founders",
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default StartUpFaqs;
