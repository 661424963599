import SectionArea from 'components/section-area/section-area'
import SEO from 'components/seo'
import PolicyArea from 'components/terms-policy/policy-area'
import FooterSeven from 'layout/footers/footer-7'
import HeaderSix from 'layout/headers/header-6'
import Wrapper from 'layout/wrapper'
import React from 'react'

const Policy = () => {
    return (
        <Wrapper>
            <SEO
                metaTitle={"Rionick-Privacy-Policy"}
                metaDesc={
                    ""
                }
                metaKeywords={
                    "about 3d modeling services, what is 3d animation, top class nft art, top digital marketing services, best website development services"
                }
                canonical="https://www.rionick.com/about-us"
                pageTitle={"About US"}
            />
            <HeaderSix top_bar={false} commonOffCanvas={true} />
            <SectionArea title={"Privacy Policy"} subtitle={""} />
            <PolicyArea />
            <FooterSeven />
        </Wrapper>

    )
}

export default Policy