import React from "react";
// internal
import skill_1 from "assets/img/skill/9/skill-icon-1.png";
import skill_2 from "assets/img/skill/9/skill-icon-2.png";
import skill_3 from "assets/img/skill/9/skill-icon-3.png";
import skill_4 from "assets/img/skill/9/skill-icon-4.png";
import CountUpContent from "components/common/counter";
import company_icon_1 from "assets/img/skill/company/9/company-icon-1.png";
import company_icon_2 from "assets/img/skill/company/9/company-icon-2.png";
import company_icon_3 from "assets/img/skill/company/9/company-icon-3.png";
import company_icon_4 from "assets/img/skill/company/9/company-icon-4.png";
import UpArrowTwo from "svg/up-arrow-2";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";

// data
const skill_data = [
  { icon: skill_1, title: "Figma", num: 90, text: "%" },
  { icon: skill_2, title: "XD", num: 66, text: "%" },
  { icon: skill_3, title: "Photoshop", num: 80, text: "%" },
  { icon: skill_4, title: "Sketch", num: 86, text: "%" },
];

// career_data
const career_data = [
  {
    icon: <i class="fi fi-rr-handshake"></i>,
    title: " Strong relationships:",
    designation:
      "We believe in delivering the best 3d modeling services to build good relationships with our clients.",
    // year: "April 2020 - Present",
  },
  {
    icon: <i class="fi fi-rs-diamond"></i>,
    title: " Premium quality:",
    designation:
      "Our 3d artist creates incredibly high quality 3d modeling and animation services to meet all the necessary criteria set by our clients.",
    // year: "April 2016 - March 2020",
  },
  {
    icon: <i class="fi fi-rr-users"></i>,
    title: "Team work:",
    designation:
      "We have a team of 3d artists and modelers to work together to deliver the best 3d modeling services in India.",
    // year: "April 2012 - March 2016",
  },
  {
    icon: <i class="fi fi-rs-lightbulb-on"></i>,
    title: "Creativity:",
    designation:
      "We generate innovative and creative ideas for designing unique 3D models and captivating digital artwork.",
    // year: "April 2008 - March 2012",
  },
];

const PortfolioSkills = ({ data = career_data }) => (
  <>
    <section className="skill__area pt-95 grey-bg-12 pb-125">
      <div className="container">
        <div className="row">
          <div className="col-xxl-5 col-xl-5 col-lg-5">
            <div className="skill__wrapper-9">
              <div className="section__title-wrapper-9 mb-55">
                {/* <span className="section__title-pre-9">Experience</span> */}
                <h3 className="section__title">
                  Our Mission & Values
                  {/* Experience */}
                </h3>
              </div>
              <div className="skill__item-wrapper-9">
                <div className="row">
                  {/* <p className="text-black">
                    To create OutStanding Visual Content That Promotes Business
                    And inspire The World
                  </p> */}
                  {/* {skill_data.map((skill, i) => (
                    <div
                      key={i}
                      className="col-xxl-6 col-md-6 col-sm-6 col-6"
                    >
                      <div className="skill__item-9">
                        <div className="skill__icon-9">
                          <span>
                            <Image src={skill.icon} alt="icon" />
                          </span>
                        </div>
                        <div className="skill__content-9">
                          <h4 className="d-flex align-items-center">
                            {skill.title}{" "}
                            <span className="counter mx-1 d-flex align-items-center">
                              (
                              <CountUpContent
                                number={skill.num}
                                text={skill.text} />
                              )
                            </span>
                          </h4>
                        </div>
                      </div>
                    </div>
                  ))} */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-7 col-xl-7 col-lg-7">
            <div className="career__wrapper career__style-2 pl-60">
              <h4 className="career__title"> </h4>
              {data.map((item, i) => (
                <div
                  key={i}
                  className="career__item transition-3 white-bg d-sm-flex align-items-center justify-content-between wow fadeInUp"
                  data-wow-delay=".3s"
                  data-wow-duration="1s"
                >
                  <div className="career__info d-sm-flex align-items-center">
                    <div className="career__logo mr-20">
                      <span>
                        {item?.image ? <>
                          <Image src={item.icon} alt="icon" />
                        </> : <>
                          {item?.icon}
                        </>}
                      </span>
                    </div>
                    <div className="career__info-content">
                      <h3 className="career__info-title">{item.title}</h3>
                      {/* <span className="career__info-designation"> */}
                      {/* </span> */}
                      {item.designation}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
);

export default PortfolioSkills;
