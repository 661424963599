import React from 'react';

const FreelancerPortOne = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.857 15.4446V22.1113H3.14274V4.33354H10.6427V2.11133H3.14274C2.57441 2.11133 2.02937 2.34545 1.62751 2.7622C1.22564 3.17895 0.999878 3.74418 0.999878 4.33354V22.1113C0.999878 22.7006 1.22564 23.2659 1.62751 23.6826C2.02937 24.0994 2.57441 24.3335 3.14274 24.3335H11.7142V28.7779H7.42845V31.0001H24.5713V28.7779H20.2856V24.3335H28.857C29.4253 24.3335 29.9704 24.0994 30.3722 23.6826C30.7741 23.2659 30.9999 22.7006 30.9999 22.1113V15.4446H28.857ZM18.1427 28.7779H13.857V24.3335H18.1427V28.7779Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
      />
      <path
        d="M18.1427 17.6667H18.132C17.9011 17.6642 17.6771 17.5844 17.4935 17.4392C17.3099 17.294 17.1764 17.0911 17.113 16.8609L15.1916 9.88899H10.6427V7.66677H15.9999C16.2327 7.66677 16.4592 7.74541 16.645 7.8908C16.8309 8.03618 16.9661 8.2404 17.0301 8.47254L18.181 12.6491L21.4047 1.78434C21.4742 1.5586 21.6105 1.36132 21.7943 1.22053C21.9781 1.07974 22.2 1.00262 22.4284 1.00012C22.6541 0.996656 22.8748 1.06869 23.0579 1.20554C23.241 1.3424 23.3767 1.53678 23.4449 1.7599L25.3435 7.66677H30.9999V9.88899H24.5713C24.3464 9.88899 24.1272 9.81561 23.9448 9.67925C23.7624 9.54289 23.6259 9.35045 23.5548 9.12921L22.4693 5.75144L19.1666 16.8825C19.0992 17.1096 18.9634 17.3083 18.7791 17.4494C18.5949 17.5905 18.3718 17.6667 18.1427 17.6667Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default FreelancerPortOne;