import React from "react";

const ErrorSvg = () => {
  return (
    <svg
      width="534"
      height="572"
      viewBox="0 0 534 572"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="error__hair"
        d="M78.6878 273.563C76.8103 270.708 72.1272 267.695 69.6817 265.977C67.0214 264.127 70.6447 262.755 72.1688 262.291C72.2372 262.27 72.2997 262.234 72.3511 262.184C72.4025 262.134 72.4413 262.073 72.4643 262.005C72.4873 261.938 72.4939 261.865 72.4834 261.795C72.473 261.724 72.4459 261.657 72.4043 261.598C72.4043 261.598 72.0094 260.816 71.76 260.712C70.6931 260.241 67.1392 261.252 67.1392 261.252C68.6287 256.901 63.5645 250.486 63.5645 250.486C63.2804 250.403 57.2395 248.789 54.6554 254.927C52.0021 261.204 60.7726 278.724 52.3415 284.024C43.9105 289.323 30.3598 305.015 35.0222 320.048C39.6846 335.081 18.7767 338.365 18.1255 351.236C17.4742 364.108 36.7057 385.612 56.6645 377.853C62.032 375.814 66.9675 372.782 71.2127 368.916C71.2127 368.729 71.1711 368.542 71.1434 368.348C70.6585 364.08 70.0212 357.437 69.6817 349.643C69.0582 334.998 69.5085 316.3 74.0947 301.842C74.2471 301.149 74.3925 300.456 74.5519 299.763C74.7112 299.071 74.9121 298.246 75.0992 297.512C75.2862 296.777 75.4456 296.182 75.6326 295.53C75.7643 295.052 75.8889 294.588 76.0414 294.145C76.1938 293.702 76.36 293.085 76.5263 292.558C76.6926 292.032 76.8242 291.623 76.9835 291.173C77.2121 290.48 77.4407 289.836 77.6763 289.192C77.9118 288.547 78.1682 287.903 78.4106 287.28C78.5215 282.361 78.8402 278.502 79.436 276.396C79.5212 275.898 79.4986 275.387 79.3695 274.898C79.2404 274.409 79.0079 273.954 78.6878 273.563Z"
        fill="#354750"
      />
      <path
        d="M64.9297 362.217C64.9297 362.217 70.1948 474.98 70.5827 479.372C70.8945 482.836 69.0725 495.167 67.292 499.532C67.2124 499.752 67.1899 499.989 67.2267 500.22C67.2634 500.451 67.3581 500.669 67.5021 500.854C67.646 501.039 67.8344 501.184 68.0496 501.276C68.2649 501.369 68.5 501.405 68.733 501.381C70.7899 501.001 72.7512 500.217 74.5038 499.074C79.9213 495.825 83.8494 477.543 85.7683 463.48C87.6873 449.416 106.344 373.419 106.344 373.419L64.9297 362.217Z"
        fill="#EAB1A2"
      />
      <path
        d="M153.141 489.258H143.969V563.883H153.141V489.258Z"
        fill="#2B0800"
      />
      <path
        d="M138.438 476.629H159.269V488.697C159.269 489.261 159.158 489.819 158.942 490.34C158.726 490.862 158.41 491.335 158.011 491.734C157.612 492.133 157.139 492.449 156.618 492.665C156.097 492.881 155.538 492.992 154.974 492.992H142.733C142.169 492.992 141.61 492.881 141.089 492.665C140.568 492.449 140.094 492.133 139.696 491.734C139.297 491.335 138.98 490.862 138.764 490.34C138.549 489.819 138.437 489.261 138.438 488.697V476.629Z"
        fill="#460D00"
      />
      <path
        d="M87.136 472.95C87.136 472.95 62.113 376.544 58.1226 344.731C56.6747 333.162 64.9049 330.349 67.5444 341.752C71.1468 357.291 100.43 462.413 100.105 466.867C99.7791 471.322 87.136 472.95 87.136 472.95Z"
        fill="#572A4D"
      />
      <path
        d="M207.178 466.673C211.335 466.542 213.413 477.876 207.178 478.076C199.447 478.333 97.4772 479.462 93.3067 479.171C85.1597 478.534 84.6678 468.253 94.0895 467.131C101.946 466.188 195.235 467.054 207.178 466.673Z"
        fill="#572A4D"
      />
      <path
        d="M171.9 566.592H125.193C124.814 566.592 124.451 566.442 124.184 566.174C123.916 565.907 123.766 565.544 123.766 565.165C123.764 564.812 123.893 564.47 124.128 564.206C124.364 563.943 124.689 563.776 125.04 563.738L148.387 561.182C148.493 561.168 148.6 561.168 148.706 561.182L172.052 563.738C172.404 563.776 172.728 563.943 172.964 564.206C173.199 564.47 173.329 564.812 173.327 565.165C173.327 565.352 173.29 565.538 173.218 565.711C173.146 565.884 173.041 566.042 172.909 566.174C172.776 566.307 172.619 566.412 172.446 566.484C172.273 566.555 172.087 566.592 171.9 566.592Z"
        fill="#79476A"
      />
      <path
        className="error__sm-leaves-3"
        d="M45.7976 567.638C45.7976 567.638 45.3404 551.94 49.2615 542.851C54.8037 530.159 58.8425 516.906 58.8425 516.906C58.8425 516.906 74.1944 550.16 67.9179 567.638H45.7976Z"
        fill="#00893D"
      />
      <path
        className="error__sm-leaves-line-3"
        d="M55.7422 567.548C56.3934 564.167 56.9061 560.724 57.3494 557.295C57.7928 553.866 58.153 550.409 58.4232 546.952C58.6934 543.495 58.9012 540.024 58.9982 536.56C59.0952 533.097 59.0952 529.633 59.0398 526.169C59.1784 529.633 59.2892 533.097 59.2407 536.56C59.1922 540.024 59.1091 543.488 58.9151 546.952C58.7211 550.416 58.444 553.88 58.0907 557.344C57.7374 560.808 57.3009 564.271 56.7052 567.735L55.7422 567.548Z"
        fill="#0E0403"
      />
      <path
        className="error__sm-leaves-2"
        d="M51.6491 567.624C51.6491 567.624 58.0919 500.252 20.5366 463.071C21.1438 469.184 20.6392 475.357 19.0472 481.291C16.4008 490.484 24.4993 497.917 24.4993 497.917L20.1625 499.767C19.2134 500.46 18.1396 501.596 18.6038 502.649C33.1521 535.729 21.5273 550.728 28.1364 567.666L51.6491 567.624Z"
        fill="#00C75B"
      />
      <path
        className="error__sm-leaves-line-2"
        d="M40.6236 567.618C40.7067 559.394 40.3604 551.136 39.7715 542.913C39.1826 534.69 38.2613 526.487 37.0004 518.327C35.7218 510.165 34.034 502.072 31.9432 494.08C29.7657 486.115 26.9366 478.344 23.4844 470.844C27.0122 478.316 29.9224 486.064 32.1856 494.01C34.3748 501.977 36.1067 510.069 37.4923 518.216C38.8778 526.363 39.7923 534.586 40.4781 542.823C41.164 551.06 41.5796 559.311 41.5796 567.604L40.6236 567.618Z"
        fill="#0E0403"
      />
      <path
        className="error__sm-leaves"
        d="M29.2217 567.624C29.2217 567.624 31.7919 555.847 22.9452 542.269C18.5876 535.577 21.622 522.178 14.8467 515.355C12.58 513.136 10.0861 511.162 7.40625 509.466C7.40625 509.466 10.0527 515.084 10.0527 520.377C10.0527 525.67 8.07135 534.094 11.2096 545.497C14.3479 556.9 10.0527 567.624 10.0527 567.624H29.2217Z"
        fill="#009D47"
      />
      <path
        className="error__sm-leaves-line"
        d="M19.9182 567.624C19.9597 563.343 19.8697 559.041 19.5164 554.78C19.3543 552.652 19.0511 550.537 18.6088 548.448C18.3733 547.43 18.0199 546.412 17.7221 545.366C17.4242 544.319 17.1679 543.287 16.9254 542.227C15.9821 538.024 15.2651 533.774 14.7778 529.494C14.6531 528.42 14.5492 527.347 14.4799 526.273C14.4106 525.199 14.4106 524.118 14.3205 523.058C14.2049 520.908 13.6209 518.808 12.6094 516.906C13.6569 518.796 14.2832 520.89 14.4452 523.044C14.5561 524.118 14.5492 525.192 14.66 526.259C14.7708 527.326 14.8817 528.4 15.0203 529.459C15.5908 533.716 16.3886 537.939 17.4103 542.11C17.6666 543.149 17.9438 544.188 18.2624 545.206C18.5811 546.225 18.9552 547.229 19.2115 548.303C19.7054 550.412 20.0526 552.554 20.2507 554.711C20.6941 559.006 20.8742 563.308 20.9088 567.631L19.9182 567.624Z"
        fill="#0E0403"
      />
      <path
        d="M533.717 566.592H0.28125V571.144H533.717V566.592Z"
        fill="#0E0403"
      />
      <path
        d="M256.781 209.058C257.316 211.368 258.617 213.428 260.473 214.903C262.328 216.379 264.629 217.182 267 217.182C269.37 217.182 271.671 216.379 273.527 214.903C275.382 213.428 276.683 211.368 277.218 209.058H256.781Z"
        fill="#FFE150"
      />
      <path d="M268.355 0H265.633V166.744H268.355V0Z" fill="#009788" />
      <path
        d="M272.102 178.964V169.453C272.102 168.734 271.817 168.045 271.309 167.537C270.801 167.029 270.112 166.744 269.393 166.744H264.599C263.881 166.744 263.192 167.029 262.684 167.537C262.176 168.045 261.891 168.734 261.891 169.453V178.964C265.283 178.567 268.71 178.567 272.102 178.964Z"
        fill="#008777"
      />
      <path
        d="M283.02 209.058C283.6 205.676 283.505 202.212 282.74 198.866C281.975 195.521 280.555 192.36 278.562 189.566C276.57 186.771 274.044 184.399 271.13 182.585C268.217 180.772 264.973 179.553 261.586 178.999L261.891 178.964C265.283 178.567 268.71 178.567 272.102 178.964C289.983 181.043 303.748 193.707 303.748 209.058H283.02Z"
        fill="#1C9183"
      />
      <path
        d="M283.396 204.645C283.398 206.124 283.273 207.601 283.022 209.058H230.281C230.282 206.059 230.812 203.083 231.847 200.267C235.9 189.183 247.441 180.731 261.636 178.999C267.715 180.001 273.239 183.13 277.225 187.827C281.211 192.524 283.398 198.485 283.396 204.645Z"
        fill="#24A69B"
      />
      <path
        d="M346.731 339.84H346.211L355.286 227.756C359.131 227.756 361.868 233.534 361.307 240.483L354.213 328.049C353.679 334.61 350.375 339.84 346.731 339.84Z"
        fill="#25A79C"
      />
      <path
        d="M375.461 362.217C369.358 362.217 344.196 362.03 343.961 362.03L343.996 358.504C344.224 358.504 369.372 358.691 375.461 358.691C376.154 358.691 377.346 358.601 377.748 357.998C378.149 357.395 377.831 355.968 377.657 355.552L377.588 355.358L360.068 295.475C359.938 294.762 359.585 294.11 359.059 293.611C358.534 293.113 357.863 292.795 357.144 292.704L357.352 289.185H357.519C358.976 289.355 360.341 289.983 361.418 290.979C362.495 291.975 363.228 293.288 363.511 294.727L380.948 354.305C381.198 354.957 382.154 357.769 380.685 359.965C379.687 361.455 377.928 362.217 375.461 362.217Z"
        fill="#25A79C"
      />
      <path
        d="M285.859 362.217V360.693L332.511 357.929V362.217H285.859Z"
        fill="#25A79C"
      />
      <path
        d="M295.594 535.909C295.594 535.909 303.083 562.997 308.549 560.787C314.015 558.577 360.015 510.574 360.015 510.574C360.015 510.574 359.398 504.256 347.545 510.574C335.692 516.893 322.494 522.885 317.957 521.423C313.419 519.961 295.594 535.909 295.594 535.909Z"
        fill="#354750"
      />
      <path
        d="M191.658 538.5C191.658 538.5 177.38 565.435 183.199 566.35C189.51 567.34 255.088 566.35 255.088 566.35C255.088 566.35 259.418 560.399 246.525 556.651C233.633 552.903 219.999 547.984 217.768 543.828C215.538 539.671 191.658 538.5 191.658 538.5Z"
        fill="#354750"
      />
      <path
        d="M104.234 411.91C104.234 411.91 228.352 386.72 242.893 402.169C257.434 417.618 317.99 521.465 317.99 521.465C315.132 524.672 311.978 527.602 308.568 530.215C304.729 533.208 300.239 535.254 295.461 536.186C295.461 536.186 239.596 470.442 212.092 466.673C194.905 464.318 134.502 474.626 119.011 464.332C105.585 455.437 104.234 411.91 104.234 411.91Z"
        fill="#1A897C"
      />
      <path
        d="M217.768 543.828C212.967 544.8 208.02 544.8 203.22 543.828C195.599 542.172 191.657 538.535 191.657 538.535C191.657 538.535 218.675 469.708 211.103 459.129C203.531 448.55 183.06 462.517 137.773 467.138C123.793 468.565 115.244 463.847 110.118 456.649C98.6384 440.521 104.243 411.965 104.243 411.965L203.026 390.399C203.026 390.399 267.571 375.851 280.651 412.374C290.225 439.087 217.768 543.828 217.768 543.828Z"
        fill="#24A69B"
      />
      <path
        d="M84.2514 276.361C82.1731 275.509 83.1499 272.482 78.7092 273.59C76.8318 270.736 72.1486 267.722 69.7031 266.004C67.0429 264.155 70.6661 262.783 72.1902 262.319C72.2586 262.298 72.3211 262.261 72.3725 262.212C72.4239 262.162 72.4627 262.101 72.4857 262.033C72.5088 261.965 72.5153 261.893 72.5049 261.822C72.4945 261.751 72.4673 261.684 72.4257 261.626C72.4257 261.626 72.0309 260.843 71.7815 260.739C70.7146 260.268 67.1606 261.28 67.1606 261.28C68.6501 256.929 63.5859 250.514 63.5859 250.514C65.5144 249.612 67.6157 249.139 69.7447 249.128C76.4785 249.163 86.7246 250.666 88.3872 259.714C89.4888 265.679 103.628 263.109 105.104 262.832H105.173H105.222C111.948 270.182 86.7107 277.38 84.2514 276.361Z"
        fill="#FDCCBB"
      />
      <path
        d="M203.012 390.344C195.855 416.787 156.956 417.119 138.431 429.679C127.659 436.988 110.118 456.614 110.118 456.614C98.6384 440.487 104.243 411.93 104.243 411.93L203.012 390.344Z"
        fill="#1C9183"
      />
      <path
        d="M203.017 390.344C190.492 406.485 166.827 412.027 145.794 413.468C131.922 414.353 117.995 413.83 104.228 411.91C93.7891 399.658 85.5822 385.668 79.9806 370.579C78.9368 367.766 78.0131 364.96 77.2095 362.161C68.5637 332.933 71.2933 305.146 78.4565 287.342C79.9667 283.469 81.9244 279.786 84.2897 276.368C86.749 277.387 112.001 270.168 105.239 262.804C120.03 259.091 134.516 263.178 134.516 263.178C223.724 284.488 203.017 390.344 203.017 390.344Z"
        fill="#FD7866"
      />
      <path
        d="M145.763 413.468C134.155 414.207 122.505 413.963 110.938 412.741C112.164 399.973 113.307 388.591 113.903 382.785C114.166 380.201 114.318 378.726 114.318 378.726C114.318 378.726 120.692 355.76 122.555 331.901C122.548 333.585 121.676 403.659 145.763 413.468Z"
        fill="#E25049"
      />
      <path
        d="M93.6122 490.803C93.6122 490.803 89.608 497.931 88.3679 496.823C87.1279 495.714 92.6839 484.526 92.7324 484.796C93.1342 486.875 93.4252 488.184 93.4875 488.586C93.6268 489.316 93.6687 490.062 93.6122 490.803Z"
        fill="#FDCCBB"
      />
      <path
        d="M81.5113 374.562L71.8125 373.329C71.8125 373.329 73.1981 380.95 75.3387 392.193C80.5484 420.043 89.984 470.477 92.7758 484.803C93.1707 486.882 93.4686 488.198 93.5241 488.593C93.6595 489.321 93.7014 490.064 93.6488 490.803C93.4548 495.618 91.2864 504.617 90.3373 508.365C90.2828 508.58 90.286 508.806 90.3465 509.02C90.407 509.234 90.5227 509.428 90.6821 509.584C90.8414 509.739 91.0387 509.849 91.2542 509.904C91.4697 509.959 91.6958 509.956 91.9099 509.896C93.9503 509.241 95.8361 508.178 97.4521 506.771C102.364 502.802 106.417 484.956 106.375 470.747C106.375 462.905 108.807 435.582 110.989 412.727C112.222 399.952 113.358 388.57 113.954 382.772C114.224 380.181 114.37 378.705 114.37 378.705L81.5113 374.562Z"
        fill="#FDCCBB"
      />
      <path
        d="M114.331 378.726C114.331 378.726 114.185 380.201 113.915 382.792C98.0504 380.88 75.937 391.875 75.2996 392.193C73.2213 380.915 71.7734 373.329 71.7734 373.329L114.331 378.726Z"
        fill="#EAB3A4"
      />
      <path
        d="M97.2169 279.5C85.1211 280.269 78.0687 289.552 74.106 302.015C66.9566 324.509 69.8524 357.319 71.2171 369.068C71.5497 371.84 71.7852 373.461 71.7852 373.461L81.5256 374.694L114.342 378.85C114.342 378.85 120.723 355.885 122.586 332.026C124.658 305.513 121.166 277.969 97.2169 279.5Z"
        fill="#FF6555"
      />
      <path
        d="M484.528 362.217H258.975C257.345 362.217 256.023 363.538 256.023 365.168V370.378C256.023 372.008 257.345 373.329 258.975 373.329H484.528C486.158 373.329 487.479 372.008 487.479 370.378V365.168C487.479 363.538 486.158 362.217 484.528 362.217Z"
        fill="#79476A"
      />
      <path
        d="M453.08 566.848H446.138L434.438 373.329H441.379L453.08 566.848Z"
        fill="#572A4D"
      />
      <path
        d="M292.641 566.848H299.582L311.283 373.329H304.342L292.641 566.848Z"
        fill="#572A4D"
      />
      <path
        className="error__leaves-3"
        d="M417.373 567.306C417.373 567.306 417.851 562.158 417.962 553.817C417.962 552.563 433.203 551.24 433.203 549.848C433.203 548.836 417.962 547.769 417.921 546.71C417.858 542.061 417.664 536.817 417.228 531.178C417.145 530.028 428.492 529.716 428.388 528.531C428.284 527.347 416.743 525.497 416.611 524.375C416.473 523.017 416.32 521.645 416.154 520.259C415.988 518.874 424.266 518.874 424.072 517.488C423.927 516.435 415.316 514.025 415.156 512.971C412.697 496.982 408.187 479.795 400.109 464.928C405.896 468.939 411.366 473.388 416.473 478.236C417.165 478.929 416.071 482.392 416.798 483.085C417.526 483.778 420.013 481.603 420.719 482.309C428.174 489.719 434.887 497.84 440.761 506.556C441.454 507.547 437.138 511.177 437.789 512.196C438.274 512.958 443.685 511.094 444.163 511.87C448.207 518.476 451.65 525.432 454.451 532.653C454.956 533.963 442.119 540.773 442.583 542.117C443.047 543.46 456.785 539.235 457.187 540.565C458.297 544.21 459.222 547.91 459.958 551.649C460.138 552.522 446.539 555.958 446.691 556.845C446.844 557.732 460.72 555.889 460.845 556.692C461.344 559.99 461.695 563.366 461.898 566.821L417.373 567.306Z"
        fill="#00893D"
      />
      <path
        className="error__leaves-line-3"
        d="M439.022 566.779C438.606 558.755 437.666 550.766 436.209 542.865C434.776 534.949 432.868 527.128 430.494 519.442C428.126 511.747 425.171 504.245 421.654 497.003L418.883 491.627C417.927 489.854 416.804 488.163 415.821 486.417L414.276 483.827C413.735 482.981 413.133 482.171 412.558 481.346L409.094 476.4L412.62 481.312C413.195 482.136 413.805 482.933 414.359 483.778L415.945 486.327C416.978 488.059 418.079 489.75 419.056 491.509L421.827 496.871C425.423 504.092 428.459 511.577 430.909 519.262C433.376 526.946 435.377 534.773 436.902 542.698C438.445 550.632 439.47 558.657 439.971 566.724L439.022 566.779Z"
        fill="#0E0403"
      />
      <path
        className="error__leaves"
        d="M435.992 566.765C435.992 566.765 436.609 560.454 438.465 550.561C438.95 547.991 457.794 539.872 457.794 539.872C458.362 537.309 441.375 536.9 442.089 534.087C442.55 532.189 443.063 530.245 443.627 528.254C444.001 526.905 444.391 525.538 444.797 524.153C446.481 518.424 448.469 512.376 450.804 506.321C451.496 504.589 464.438 507.492 465.165 505.746C465.165 505.746 454.51 497.259 455.376 495.354C459.934 485.047 465.639 475.287 472.384 466.258C473.139 465.267 485.366 468.052 485.366 468.052C486.059 467.2 476.097 461.623 476.81 460.799C486.586 449.5 498.619 440.327 513.403 435.409C513.403 435.409 511.428 440.203 508.678 448.703C506.212 456.323 503.136 466.937 500.261 479.746C499.942 481.187 486.052 481.921 485.713 483.418C485.713 483.418 498.737 486.778 498.474 488.122C497.965 490.671 497.474 493.292 496.998 495.985C496.832 496.961 488.484 496.061 488.317 497.058C488.123 498.202 496.118 501.215 495.938 502.414C494.365 512.466 493.105 523.322 492.419 534.745C492.363 535.646 476.485 537.419 476.423 538.327C476.423 538.327 492.183 539.207 492.142 540.08C492.065 541.895 492.003 543.717 491.954 545.56C491.954 547.063 472.1 548.705 472.086 550.229C472.086 551.462 491.857 552.577 491.857 553.824C491.857 558.083 491.959 562.392 492.162 566.752L435.992 566.765Z"
        fill="#25C168"
      />
      <path
        className="error__leaves-line"
        d="M465.164 566.758C465.579 556.16 466.583 545.593 468.171 535.106C469.703 524.617 471.833 514.225 474.551 503.979C475.937 498.867 477.468 493.789 479.207 488.78C480.945 483.771 482.899 478.845 485.081 474.024C487.28 469.204 489.739 464.507 492.446 459.953C495.195 455.426 498.255 451.095 501.604 446.992C498.289 451.119 495.263 455.471 492.549 460.016C489.882 464.585 487.465 469.296 485.31 474.128C483.162 478.977 481.285 483.903 479.56 488.905C477.835 493.906 476.38 498.991 475.029 504.104C472.401 514.348 470.356 524.733 468.905 535.21C467.398 545.671 466.473 556.208 466.134 566.772L465.164 566.758Z"
        fill="#0E0403"
      />
      <path
        className="error__leaves-2"
        d="M439.497 566.765C439.497 566.765 437.245 553.603 434.044 547.423C433.213 545.809 419.101 544.188 419.101 544.188C418.215 542.601 429.389 538.944 428.357 537.219C427.664 536.062 426.971 534.87 426.175 533.665C425.662 532.84 425.128 532.016 424.581 531.178C422.323 527.714 419.808 524.125 417.051 520.557C416.261 519.539 408.239 524.021 407.414 523.037C407.414 523.037 412.783 515.285 411.827 514.191C407.765 509.482 403.305 505.132 398.498 501.187C398.055 500.827 392.118 505.753 391.674 505.406C391.335 505.136 396.482 499.594 396.136 499.331C395.679 498.984 395.221 498.638 394.75 498.312C394.058 497.8 386.499 502.088 386.499 502.088C385.876 501.658 391.349 495.978 390.725 495.576C381.996 490.034 372.194 486.355 361.414 486.043C361.414 486.043 363.679 488.814 367.199 493.913C367.677 494.606 372.048 494.128 372.609 494.918C372.935 495.403 369.374 497.121 369.713 497.64C372.429 501.749 375.616 506.847 378.962 512.805C379.461 513.699 388.813 511.42 389.319 512.348C389.319 512.348 381.352 517.197 381.802 518.008C382.641 519.601 383.493 521.236 384.338 522.927C384.65 523.536 385.973 526.238 386.333 526.972C389.381 533.325 392.381 540.267 395.111 547.7C395.325 548.289 406.195 546.315 406.431 546.89C406.431 546.89 396.157 550.617 396.358 551.185C396.766 552.37 397.175 553.568 397.57 554.78C397.896 555.771 411.363 552.903 411.675 553.914C411.931 554.732 399.039 559.415 399.281 560.239C400.126 563.057 400.918 565.934 401.657 568.871L439.497 566.765Z"
        fill="#009D47"
      />
      <path
        className="error__leaves-line-2"
        d="M423.844 567.791C420.983 560.544 417.706 553.429 414.187 546.453C410.668 539.477 406.837 532.653 402.639 526.065C398.482 519.439 393.852 513.123 388.783 507.166C383.75 501.169 377.956 495.854 371.547 491.357C378.012 495.792 383.869 501.053 388.97 507.007C394.111 512.929 398.818 519.215 403.054 525.816C407.336 532.383 411.222 539.193 414.831 546.135C418.441 553.076 421.759 560.177 424.752 567.451L423.844 567.791Z"
        fill="#0E0403"
      />
      <path
        d="M470.504 347.648H404.773V349.602H463.465C463.991 349.628 464.485 349.862 464.839 350.251C465.193 350.641 465.379 351.154 465.356 351.68V358.095C465.379 358.621 465.193 359.134 464.839 359.524C464.485 359.913 463.991 360.146 463.465 360.173H404.773V362.196H470.504C471.341 362.151 472.127 361.777 472.69 361.156C473.253 360.534 473.548 359.716 473.51 358.878V350.973C473.55 350.134 473.256 349.314 472.693 348.691C472.13 348.068 471.343 347.693 470.504 347.648Z"
        fill="#354750"
      />
      <path
        d="M466.17 358.102V351.687C466.193 351.162 466.008 350.65 465.655 350.261C465.302 349.871 464.81 349.637 464.286 349.608H405.781V360.201H464.286C464.814 360.172 465.309 359.935 465.663 359.541C466.016 359.148 466.199 358.63 466.17 358.102Z"
        fill="white"
      />
      <path
        d="M409.662 333.917H468.215V335.781H413.167C412.673 335.808 412.209 336.029 411.877 336.396C411.545 336.763 411.371 337.247 411.393 337.741V343.761C411.378 344.251 411.555 344.728 411.887 345.089C412.218 345.45 412.678 345.667 413.167 345.694H468.236V347.627H409.682C408.896 347.588 408.158 347.238 407.629 346.655C407.101 346.072 406.825 345.303 406.863 344.517V337.028C406.827 336.245 407.1 335.48 407.624 334.898C408.148 334.316 408.88 333.964 409.662 333.917Z"
        fill="#FF6555"
      />
      <path
        d="M411.378 343.754V337.734C411.351 337.243 411.52 336.762 411.847 336.394C412.174 336.027 412.633 335.804 413.124 335.774H467.264V345.694H413.124C412.637 345.664 412.181 345.444 411.854 345.081C411.528 344.718 411.357 344.242 411.378 343.754Z"
        fill="white"
      />
      <path
        d="M420.037 329.982V323.962C420.015 323.467 420.188 322.984 420.52 322.617C420.853 322.249 421.316 322.028 421.811 322.001H458.583V320.138H418.901C418.115 320.179 417.376 320.53 416.848 321.114C416.319 321.699 416.044 322.468 416.082 323.255V330.758C416.044 331.545 416.319 332.315 416.848 332.899C417.376 333.483 418.115 333.834 418.901 333.876H458.583V331.943H421.811C421.316 331.916 420.853 331.695 420.52 331.328C420.188 330.96 420.015 330.477 420.037 329.982Z"
        fill="#24A69B"
      />
      <path
        d="M421.706 322.001C421.226 322.044 420.782 322.273 420.469 322.64C420.157 323.007 420.001 323.481 420.036 323.962V329.982C420.001 330.463 420.157 330.937 420.469 331.304C420.782 331.671 421.226 331.9 421.706 331.943H456.898V322.001H421.706Z"
        fill="white"
      />
      <path
        className="error__z z-1"
        d="M73.3176 192.744C69.735 191.86 66.0999 191.205 62.4341 190.783C61.9633 190.7 61.4785 190.768 61.0486 190.977C60.8961 191.057 60.7665 191.175 60.672 191.319C60.5775 191.464 60.5211 191.629 60.5082 191.801C60.4931 191.968 60.5194 192.135 60.5847 192.288C60.65 192.442 60.7522 192.577 60.8823 192.681C61.2588 192.954 61.7063 193.113 62.1708 193.138C64.9384 193.46 67.6876 193.922 70.4079 194.524C66.6683 196.871 63.1403 199.539 59.8639 202.498L59.3374 202.976C59.1365 203.163 58.9771 203.315 58.8663 203.44C58.7541 203.557 58.6606 203.69 58.5892 203.835C58.5193 203.986 58.4771 204.147 58.4645 204.313C58.4362 204.545 58.4609 204.78 58.5364 205C58.612 205.221 58.7366 205.422 58.9009 205.588C59.329 205.966 59.875 206.184 60.4458 206.204C63.8661 206.585 67.2537 207.217 70.5811 208.095C70.956 208.238 71.3701 208.238 71.745 208.095C71.8945 208.021 72.0259 207.915 72.13 207.785C72.2342 207.654 72.3085 207.503 72.3477 207.34C72.3988 207.188 72.4136 207.026 72.3907 206.867C72.3678 206.709 72.308 206.557 72.2161 206.426C71.9524 206.116 71.5905 205.906 71.1907 205.83C68.3867 205.092 65.5413 204.52 62.6696 204.119C65.7733 201.483 69.0891 199.108 72.5832 197.018C73.1713 196.687 73.7424 196.326 74.2944 195.937C74.6239 195.655 74.8525 195.273 74.9456 194.85C75.2504 193.804 74.7031 193.09 73.3176 192.744Z"
        fill="#572A4D"
      />
      <path
        className="error__z z-2"
        d="M90.736 203.98C88.4268 202.757 86.0621 201.646 83.642 200.648C83.2749 200.468 82.859 200.412 82.4573 200.489C82.3008 200.521 82.1551 200.593 82.0339 200.697C81.9127 200.801 81.82 200.935 81.7646 201.085C81.6782 201.243 81.6497 201.426 81.6838 201.603C81.718 201.78 81.8127 201.94 81.9516 202.054C82.3791 202.359 82.845 202.606 83.3372 202.789C84.8613 203.426 86.36 204.119 87.8333 204.867C87.5423 204.999 87.1405 205.144 86.7179 205.311L85.0483 205.92C84.3555 206.163 83.6628 206.433 82.9284 206.731C82.1941 207.029 81.3489 207.368 80.4552 207.763C79.5615 208.158 79.0697 208.456 78.7787 208.587C78.5339 208.715 78.3406 208.923 78.2314 209.176C78.1558 209.346 78.1196 209.531 78.1256 209.717C78.1316 209.903 78.1797 210.085 78.2661 210.25C78.5063 210.63 78.8713 210.914 79.2983 211.054C81.7548 212.07 84.1511 213.227 86.4754 214.517C86.7998 214.726 87.1879 214.812 87.57 214.76C87.7165 214.732 87.8547 214.671 87.9746 214.582C88.0945 214.494 88.193 214.379 88.2628 214.247C88.347 214.115 88.4001 213.965 88.4181 213.809C88.4361 213.653 88.4186 213.495 88.3667 213.347C88.2157 212.985 87.9337 212.693 87.577 212.529C85.8404 211.569 84.0669 210.682 82.2565 209.869C84.8103 208.807 87.4242 207.895 90.0848 207.139L90.8399 206.932C91.0477 206.876 91.2209 206.821 91.3526 206.772C91.4991 206.713 91.6367 206.634 91.7613 206.537C91.8969 206.439 92.0103 206.314 92.0939 206.17C92.2127 206.005 92.2767 205.808 92.2767 205.605C92.2767 205.402 92.2127 205.205 92.0939 205.04C91.724 204.592 91.2605 204.23 90.736 203.98Z"
        fill="#572A4D"
      />
      <path
        className="error__z z-3"
        d="M108.006 216.672C107.756 216.151 107.394 215.692 106.946 215.328C104.978 213.564 102.937 211.899 100.821 210.333C100.509 210.071 100.12 209.916 99.713 209.89C99.5562 209.888 99.4014 209.925 99.2624 209.997C99.1233 210.07 99.0044 210.176 98.9163 210.305C98.7926 210.438 98.7199 210.611 98.711 210.792C98.7021 210.973 98.7577 211.152 98.8678 211.296C99.2069 211.693 99.5957 212.045 100.025 212.342C101.355 213.331 102.653 214.367 103.918 215.453C103.599 215.508 103.225 215.557 102.72 215.612L100.946 215.792C100.253 215.861 99.4913 215.952 98.6877 216.049C97.8841 216.146 96.9974 216.284 96.0275 216.45C95.0576 216.617 94.5034 216.748 94.1985 216.838C93.9221 216.899 93.6763 217.056 93.5058 217.282C93.3935 217.429 93.3154 217.599 93.2771 217.78C93.2387 217.961 93.2411 218.148 93.2841 218.328C93.4266 218.755 93.7131 219.12 94.0946 219.36C96.2531 220.952 98.3186 222.666 100.281 224.493C100.54 224.776 100.891 224.958 101.272 225.006C101.422 225.017 101.573 224.994 101.713 224.938C101.853 224.883 101.979 224.798 102.082 224.687C102.195 224.577 102.282 224.442 102.336 224.293C102.389 224.145 102.409 223.986 102.394 223.828C102.331 223.439 102.126 223.086 101.819 222.838C100.35 221.452 98.8309 220.15 97.2606 218.93C100.015 218.534 102.791 218.303 105.574 218.238H106.371H106.904C107.062 218.219 107.216 218.177 107.361 218.113C107.518 218.051 107.66 217.957 107.777 217.836C107.938 217.695 108.049 217.504 108.09 217.293C108.131 217.082 108.102 216.864 108.006 216.672Z"
        fill="#572A4D"
      />
    </svg>
  );
};

export default ErrorSvg;
