import { useFormik } from "formik";
import React, { useRef } from "react";
// internal
import ErrorMsg from "./error-msg";
import { portfolio_schema } from "utils/validation-schema";
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const PortfolioContactForm = () => {
  const formref = useRef()
  // use formik
  const { handleChange, handleSubmit, handleBlur, errors, values, touched } =
    useFormik({
      initialValues: {
        to_name: "",
        to_email: "",
        phone: "",
        subject: "",
        message: "",
      },

      validationSchema: portfolio_schema,
      onSubmit: (values, { resetForm }) => {
        console.log(values);
        console.log('====================================');
        console.log({ formref });
        console.log('====================================');
        emailjs.sendForm('service_xvfs9yi', 'template_pfxv1ic', formref.current, 'NVaN2eE7imlE7CGWV')
          .then((result) => {
            console.log(result);
            toast.success("Message Sent SuccessFully ..", {
              style: {
                top: 100,

              }
            })
            resetForm();
          }).catch(({ err }) => {
            console.log(err);
          })
      },
    });
  return (
    <>
      <ToastContainer />
      <form id="contact-form" onSubmit={handleSubmit} ref={formref}>
        <div className="row">
          <div className="col-lg-6">
            <div className="contact__input-9">
              <input
                name="to_name"
                value={values.to_name}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                placeholder="Your name*"
                id="to_name"
              />
              <input
                name="from_name"
                value={"Rionick Studio"}
                onChange={handleChange}
                onBlur={handleBlur}
                type="hidden"
                placeholder="Your name*"
                id="name"
              />
              {touched.to_name && <ErrorMsg error={errors.to_name} />}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="contact__input-9">
              <input
                name="to_email"
                value={values.to_email}
                onChange={handleChange}
                onBlur={handleBlur}
                type="email"
                placeholder="Your email address*"
                id="email"
              />
              {touched.to_email && <ErrorMsg error={errors.to_email} />}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="contact__input-9">
              <input
                name="phone"
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                placeholder="Mobile Number"
                id="phone"
              />
              {touched.phone && <ErrorMsg error={errors.phone} />}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="contact__input-9">
              <input
                name="subject"
                value={values.subject}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                placeholder="Subject"
                id="subject"
              />
              {touched.subject && <ErrorMsg error={errors.subject} />}
            </div>
          </div>
          <div className="col-lg-12">
            <div className="contact__input-9">
              <textarea
                name="message"
                value={values.message}
                onChange={handleChange}
                onBlur={handleBlur}
                id="msg"
                placeholder="How can we help you?"
              ></textarea>
              {touched.message && <ErrorMsg error={errors.message} />}
            </div>
          </div>
          <div className="col-lg-12">
            <div className="contact__btn-9">
              <button type="submit" className="tp-btn-6 w-100">
                SEND MESSAGE
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default PortfolioContactForm;
