import React from "react";

const social_links = [
  {
    link: "https://www.facebook.com/rionickstudios?mibextid=ZbWKwL",
    target: "_blank",
    icon: "fa-brands fa-facebook-f",
    name: "Facebook",
  },
  {
    link: "https://www.instagram.com/rionick.studios/",
    target: "_blank",
    icon: "fa-brands fa-instagram",
    name: "Instagram",
  },
  {
    link: "https://www.linkedin.com/company/78377992/admin/feed/posts/",
    target: "_blank",
    icon: "fa-brands fa-linkedin-in",
    name: "Linkedin",
  },
  {
    link: "https://www.youtube.com/@rionickstudios",
    target: "_blank",
    icon: "fa-brands fa-youtube",
    name: "Youtube",
  },
  {
    link: "https://wa.me/918377935429",
    target: "_blank",
    icon: "fa-brands fa-whatsapp",
    name: "Whatsapp",
  },
];

const SocialLinks = () => {
  return (
    <>
      {social_links.map((l, i) => (
        <a key={i} href={l.link} target={l.target}>
          <i className={l.icon}></i>
        </a>
      ))}
    </>
  );
};

export default SocialLinks;

export function HeaderSocial() {
  return (
    <>
      {social_links.slice(0, 3).map((l, i) => (
        <li key={i}>
          <a href={l.link} target={l.target}>
            <i className={l.icon}></i>
          </a>
        </li>
      ))}
    </>
  );
}

export function SocialShare() {
  return (
    <>
      {social_links.slice(0, 3).map((l, i) => (
        <a key={i} href={l.link} target={l.target}>
          <i className={l.icon}></i>
        </a>
      ))}
    </>
  );
}
