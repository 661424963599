import React, { useState } from "react";
// import { Image } from "react-bootstrap";
// internal
import vision_img_1 from "assets/img/Main Images/Home/3d services.jpg";
import vision_img_2 from "assets/img/Main Images/Home/best graphic design services delhi.jpg";
import vision_img_3 from "assets/img/Main Images/Home/Non fungible token.jpg";
import vision_img_4 from "assets/img/Main Images/Home/best digital marketing services india.jpg";
import vision_img_5 from "assets/img/Main Images/Home/best website development delhi.jpg";
import { NavItem, TabItem } from "./politician-vision";
import {
  Analytics,
  MobileAppTwo,
  Optimized,
  PixelPerfect,
  Skill,
} from "svg/index";
import data from "data/home-data.json";

const ServicesVision = () => {
  const [Items, setItems] = useState("one-tab");
  const handleItems = async (e) => {
    setItems(e.target.id);
  };
  return (
    <>
      <section className="vision__area vision__style-2 pt-110 pb-110 grey-bg-4">
        <div className="container">
          <div className="row">
            <div className="col-xxl-8 col-xl-9 col-lg-10">
              <div className="tp-section-wrapper-2 mb-60">
                <span className="tp-section-subtitle-2"></span>
                <h3 className="tp-section-title-2">
                  {data?.home.service?.title}
                  {/* Our various types of services especially for you */}
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-5">
              <div className="vision__tab tp-tab">
                <ul
                  className="nav nav-tabs flex-column"
                  id="visionTab"
                  role="tablist"
                >
                  <NavItem
                    delay=".1s"
                    id="one"
                    active={Items === "one-tab"}
                    icon={<i class="fi fi-rr-cubes"></i>}
                    title="3D Services"
                    onClick={handleItems}
                  />
                  <NavItem
                    delay=".3s"
                    active={Items === "two-tab"}
                    id="two"
                    onClick={handleItems}
                    icon={<i class="fi fi-rr-pencil-paintbrush"></i>}
                    title="2D Services"
                  />
                  <NavItem
                    delay=".5s"
                    id="three"
                    active={Items === "three-tab"}
                    icon={<i class="fi fi-br-stats"></i>}
                    title="NFT Art"
                    onClick={handleItems}
                  />
                  <NavItem
                    delay=".7s"
                    id="four"
                    icon={<i class="fi fi-br-hastag"></i>}
                    title="Digital Marketing"
                    active={Items === "four-tab"}
                    onClick={handleItems}
                  />
                  <NavItem
                    delay=".9s"
                    id="five"
                    icon={<i class="fi fi-sr-globe"></i>}
                    active={Items === "five-tab"}
                    title="Web Development"
                    onClick={handleItems}
                  />
                </ul>
              </div>
            </div>

            <div className="col-xxl-9 col-xl-8 col-lg-8 col-md-7">
              <div className="vision__tab-content pl-70">
                <div className="tab-content" id="visionTabContent">
                  <TabItem
                    id="one"
                    active={Items === "one-tab"}
                    img={vision_img_1}
                    desc="We specialize in offering best 3D modeling, best 3D animation, texturing, product presentation, and 3D mockup services across the industries. "
                    lists={{
                      // "Customized models": "/link",

                      // "High quality models": "",
                      "Know more": "/best-3d-services-india",
                    }}
                  />
                  <TabItem
                    active={Items === "two-tab"}
                    id="two"
                    img={vision_img_2}
                    desc="We provide 2D art and animation services in Delhi of various scopes to suit your business needs, from graphic designing to motion graphics and video presentation. "
                    lists={{
                      // "Conceptual and creative designs",
                      // "Helps in brand building",
                      "Know more": "/graphic-designing-services-india",
                    }}
                  />
                  <TabItem
                    id="three"
                    active={Items === "three-tab"}
                    img={vision_img_3}
                    desc="We are a NFT development company where we provide 3d digital art services that are  unique, eye-catching and are of high quality. "
                    lists={{
                      "Know more": "/non-fungible-token-development-services-india",
                    }}
                  />
                  <TabItem
                    id="four"
                    img={vision_img_4}
                    desc="We offer the best digital marketing services in India to startups and various businesses. Our digital marketing experts will help you expand your business to reach new heights."
                    lists={
                      //   [
                      //   "Promote greater consumer engagement",
                      //   "Build your brand reputation across online channels",
                      // ]
                      {
                        "Know more": "/best-digital-marketing-services-india",
                      }
                    }
                    active={Items === "four-tab"}
                  />
                  <TabItem
                    id="five"
                    img={vision_img_5}
                    desc="With our end-to-end web development services, we offer best website design, UX and UI design in India, and continuous support and integration of advanced tech modules."
                    lists={{
                      "Know more": "/best-web-development-services-india",
                    }}
                    active={Items === "five-tab"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServicesVision;
