import ServiceThreeAbout from "components/abouts/service-3-about";
import AgencyCta from "components/cta/agency-cta";
import SEO from "components/seo";
import ServiceThreeVideoArea from "components/video-area/service-3-video-area";
import ElementsCarouselMain from "elements/elements-carousel";
import CarouselWithControls from "elements/elements-carousel/carousel-with-controls";
import PortfolioSliderGalleryEl from "elements/elements-carousel/portfolio-slider-gallery-el";
import FooterSeven from "layout/footers/footer-7";
import HeaderSix from "layout/headers/header-6";
import Wrapper from "layout/wrapper";
import React from "react";

// Banner Image

import bannericonnft from "assets/img/Main Images/NFT/rionickicon.png"
import bannernft from "assets/img/Main Images/NFT/video-bg-2.jpg"

// NFT Service Images

import NFTService1 from "assets/img/Main Images/NFT/Non fungible token development services.jpg"
import NFTService2 from "assets/img/Main Images/NFT/Non fungible token development services 2.jpg"

const NFT = () => {
  return (
    <Wrapper>
      <SEO
        metaTitle={"NFT Development Services | 2D & 3D NFT Art"}
        metaDesc={
          "We provide 2d and 3d nft art services that are unique, eye-catching and customized."
        }
        metaKeywords={
          "nft art services, nft development services, digital art, nft price, nft development in delhi ncr, nft developer, nft development, nft development cost"
        }
        canonical="https://www.rionick.com/non-fungible-token-development-services-india"
        pageTitle={"Nft"}
      />
      <HeaderSix top_bar={false} commonOffCanvas={true} />
      <ServiceThreeVideoArea
        banner_icon= {bannericonnft}
        video_bg={bannernft}
        t1="We are here for you."
        t2="We offer one-of-a-kind NFT development services including digital art, music, videos, and more"
      />
      {/* <ServiceThreeFeature/> */}
      {/* <ServiceThreeServices /> */}
      <ServiceThreeAbout
        list={[
          "Arts",
          "Music",
          "Fashion",
          "Games",
          "Real-estate",
          "Digital content",
        ]}
        t1="What industries fall within the scope of NFT development services?"
        p="Non-Fungible Tokens or NFTs effectively caters the needs of the following industries: "
        reverse={true}
        about_img={NFTService1}

      />
      <ServiceThreeAbout
        t1="Why choose us for NFT Development services?"
        p="We have a team of expert developers and designers who create unique and customized digital artworks comprising music, videos, songs, paintings, and social media tweets for various industries."
        reverse={false}
        about_img={NFTService2}

      />
      <PortfolioSliderGalleryEl />
      <AgencyCta
        bclr="tp-btnr bg-danger"
        title="Get custom NFT solutions for your business"
        btext="Get in Touch"
        content="Discuss your project with our specialist"
        element_style={true}
      />

      <FooterSeven />
    </Wrapper>
  );
};

export default NFT;
