import React from "react";
// internal
import {
  ArrowRightLongTwo,
  CalenderThree,
  CommentBigTwo,
  MobileAppThree,
  SeoOptimize,
} from "svg/index";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";

// single service
function SingleService({ val }) {
  return (
    <div className="col-xl-6 col-lg-6">
      <div className="services__item-15 d-sm-flex align-items-start transition-3 mb-30">
        <div className="services__icon-15 mr-45">
          {val?.image ? <>
            <Image src={val.icon} alt="icon" />
          </> : <>
          <span>{val.icon}</span>
          </>}
        </div>
        <div className="services__content-15">
          <h3 className="services__title-15">
            <Link href="/service-details">{val.title}</Link>
          </h3>
          <p>{val.subtitle}</p>

          {/* <div className="services__btn-15">
            <Link href="/service-details">
              Learn More
              <span>
                <ArrowRightLongTwo />
              </span>
            </Link>
          </div> */}
        </div>
      </div>
    </div>
  );
}

const ServiceThreeServices = ({ list = [], element_style = false }) => {
  return (
    <section className="services__area pt-110 pb-100 grey-bg-15">
      <div className="container">
        <div className="row">
          <div className="col-xxl-12">
            <div className="tp-section-wrapper-3 mb-50 text-center">
              <span className="tp-section-subtitle-3">
                {element_style ? "LIST GRID" : "Our Services"}
              </span>
              <h3 className="tp-section-title-3">
                {element_style ? "List Grid Style" : "Our featured services"}
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          {list.map((val) => {
            return (
              <SingleService
                val={val}
              />
            );
          })}
          {/* <SingleService
            icon={<MobileAppThree />}
            title="3D Modeling"
            subtitle={
              <>
                Our 3D modeling studio provides best 3D modeling services by
                dealing with 3D projects of any complexity and delivering them
                on timelines without compromising on the quality of 3D models.
              </>
            }
          />
          <SingleService
            icon={<SeoOptimize />}
            title="3D Animation"
            subtitle={
              <>
                Our 3D artists are expert in providing top-notch 3D animation
                services tailored to suit our clients requirements and business
                goals.
              </>
            }
          />
          <SingleService
            icon={<CalenderThree />}
            title="Texturing"
            subtitle={
              <>
                We provide 3D texturing services by adding different textures to
                the 3D models like adding colors, patterns and other details to
                the models to give the model a realistic look.
              </>
            }
          />
          <SingleService
            icon={<CommentBigTwo />}
            title="3D Product Designing"
            subtitle={
              <>
                We provide 3D product designing services to our clients to give
                their products a more detailed look and animation, including
                information about its design, features, and functionality.
              </>
            }
          /> */}
        </div>
      </div>
    </section>
  );
};

export default ServiceThreeServices;
