import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/swiper-bundle.min.css"; // Import Swiper CSS
import text_bg from "assets/img/testimonial/testimonial-text-bg.jpg";
import { ArrowRightThree, Quete } from "svg/index";
import user_1 from "assets/img/users/user-9.jpg";
import user_2 from "assets/img/users/user-8.jpg";
import user_3 from "assets/img/users/user-7.jpg";
import user_4 from "assets/img/users/user-7.jpg";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";

const testimonial_items = [
  {
    user: user_1,
    ratings: [1, 2, 3, 4, 5],
    desc: "Enhance customer engagement with our best digital marketing services and make 3d videos to connect with your target audience.",
    name: "Brand Engagement",
    title: "CEO at Fast Ventures",
  },
  {
    user: user_2,
    ratings: [1, 2, 3, 4, 5],
    desc: "Elevate your business's presence and attract attention with our 3d animation and 3d character design expertise, making your brand stand out from the competition.",
    name: "Level Up Your Competitiveness",
    title: "Developer at ThemePure",
  },
  {
    user: user_3,
    ratings: [1, 2, 3, 4, 5],
    desc: "Let Rionick Studio bring your business vision to life with creative content creation and social media branding enabling effective communication and engagement.",
    name: "Visualize Ideas Effectively",
    title: "MD at Stark Ltd.",
  },
  {
    user: user_4,
    ratings: [1, 2, 3, 4, 5],
    desc: "Creative copywriting and great digital marketing lead to business growth and help in impactful advertising and marketing campaigns, to propel your brand's success.",
    name: "Boost Your Marketing",
    title: "MD at Stark Ltd.",
  },
  // Add more testimonial items here...
];

const AboutMeTestimonial = () => {
  const [loop, setLoop] = useState(false);
  useEffect(() => setLoop(true), []);

  const settings = {
    centeredSlides: true,
    slidesPerView: 1,
    spaceBetween: 0,
    loop: loop,
    pagination: {
      el: ".testimonial-slider-dot-14", // This class should exist in your HTML
      clickable: true,
    },
  };

  return (
    <section className="testimonial__area pb-120 pt-100">
      <div className="container">
        <div className="row">
          <div className="col-xl-5 col-lg-5">
            <div
              className="testimonial__wrapper-14 wow fadeInUp"
              data-wow-delay=".3s"
              data-wow-duration="1s"
            >
              <div className="testimonial__info">
                <div className="section__title-wrapper-9 mb-10">
                  <span className="section__title-pre section__title-pre-1-3">
                    Key Attributes
                  </span>
                </div>
                <h3
                  className="testimonial__info-title has-background"
                  style={{ backgroundImage: `url(${text_bg})` }}
                >
                  Why
                </h3>
                <p>Opt For Rionick Studios?</p>
                <div className="testimonial__info-btn">
                  {/* <Link href="/portfolio-2" className="tp-btn tp-link-btn-3">
                  Why Rionick Studios?
                    <span>
                      <ArrowRightThree />
                    </span>
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-7 col-lg-7">
            <div
              className="testimonial__slider-14 testimonial__style-black p-relative wow fadeInUp"
              data-wow-delay=".6s"
              data-wow-duration="1s"
            >
              <div className="testimonial__slider-active-14 swiper-container">
                <Swiper {...settings} modules={[Pagination]}>
                  {testimonial_items.map((item, i) => (
                    <SwiperSlide
                      key={i}
                      className="testimonial__item-4 d-sm-flex align-items-center"
                    >
                      {/* <div className="testimonial__avater-thumb-4">
                        <Image src={item.user} alt="user" />
                      </div> */}
                      <div className="testimonial__content-4">
                        <div className="testimonial__icon">
                          <span>
                            {/* Uncomment this if you have a Quete component */}
                            {/* <Quete /> */}
                          </span>
                        </div>
                        <div className="testimonial__rating">
                          {item.ratings.map((r) => (
                            <a href="#" key={r}>
                              <i className="fa-solid fa-star"></i>
                            </a>
                          ))}
                        </div>
                        <h4 className="testimonial__avater-title-4">
                          {item.name}
                        </h4>
                        <p>{item.desc}</p>
                        {/* <div className="testimonial__avater-info-4">
                          <h4 className="testimonial__avater-title-4">
                            {item.name}
                          </h4>
                          <span className="testimonial__avater-designation-4">
                            {item.title}
                          </span>
                        </div> */}
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <div className="testimonial-slider-dot-14 tp-swiper-dot"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutMeTestimonial;
