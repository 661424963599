import ServiceThreeAbout from "components/abouts/service-3-about";
import AgencyCta from "components/cta/agency-cta";
import SEO from "components/seo";
import ServiceThreeServices from "components/services/service-3-services";
import ServiceThreeVideoArea from "components/video-area/service-3-video-area";
import FooterSeven from "layout/footers/footer-7";
import HeaderSix from "layout/headers/header-6";
import Wrapper from "layout/wrapper";
import React from "react";
import CalenderThree from "svg/calender-3";
import CommentBigTwo from "svg/comment-big-2";
import MobileAppThree from "svg/mobile-app-3";
import SeoOptimize from "svg/seo-optimize";

// Banner Image

import bannericondm from "assets/img/Main Images/Digital Marketing/rionickicon.png"
import bannerdm from "assets/img/Main Images/Digital Marketing/video-bg-2.jpg"

// Digital Marketing Service Images

import DMService1 from "assets/img/Main Images/Digital Marketing/Best marketing services.jpg"
import DMService2 from "assets/img/Main Images/Digital Marketing/Digital marketing services.jpg"

const ListDevelopment = [
  {
    icon: <i class="fi fi-rr-bullseye-arrow"></i>,
    title: "Digital Marketing and Strategy",
    subtitle:
      "Provides performance-driven digital marketing services, including various digital strategies, branding campaigns, social media and more.",
  },
  {
    icon: <i class="fi fi-rs-lightbulb-exclamation"></i>,
    title: "Social Media Optimisation",
    subtitle:
      "With our expertise, build your brand by engaging with your audience. Start, maintain, and increase your relationships with followers through us.",
  },
  {
    icon: <i class="fi fi-rr-browser"></i>,
    title: "Content Marketing",
    subtitle:
      "With the help of our content wizards, your brand's vision will be conveyed with precision and emotion which will help you build a sense of trust with your audience.",
  },
  {
    icon: <i class="fi fi-rr-megaphone"></i>,
    title: "PPC (Paid Advertising)",
    subtitle:
      "Our online marketing strategies help increase traffic to the website and drive conversions for your business, helping you achieve ROI focused results.",
  },
];
const DigitalMarketing = () => {
  return (
    <Wrapper>
      <SEO
        metaTitle={
          "op Digital Marketing Services in India- SEO, PPC & Social Media- Rionick"
        }
        metaDesc={
          "We provide top digital marketing services such as SEO, PPC & Social media Optimization for start-ups, medium sized enterprises and multi locational companies with an aim to convert their businesses into brands."
        }
        metaKeywords={
          "best digital marketing services in india, best seo services in delhi ncr, ppc services in delhi, seo package, seo price, digital marketing near me, digital marketing cost, social media marketing, best digital marketing agency in india"
        }
        canonical="https://www.rionick.com/best-digital-marketing-services-india"
        pageTitle={"Digital Marketing"}
      />
      <HeaderSix top_bar={false} commonOffCanvas={true} />
      <ServiceThreeVideoArea
        banner_icon= {bannericondm}
        video_bg={bannerdm}
        t1="We are here for you."
        t2="Grow your business with our best Digital Marketing Services"
      />
      <ServiceThreeServices list={ListDevelopment} />
      <ServiceThreeAbout
        t1="How does digital marketing contribute to business growth?"
        p="Online marketing helps boost businesses by engaging with the targeted audience at the right time on the right platform and making their brand stand out from their competitors and successfully achieve their conversion goals. "
        reverse={true}
        about_img={DMService1}

      />
      <ServiceThreeAbout
        t1="Why are our digital marketing services best for your business?"
        p="From startups to medium-sized enterprises to multi-location companies, our digital marketing strategies will help you expand your niche market reach to your target customers. We strategize and execute to deliver the best."
        reverse={false}
        about_img={DMService2}

      />
      <AgencyCta
        content="Let’s grow together"
        title="Let your business reach new heights with us"
        btext="Get in Touch"
        bclr="tp-btnr bg-danger tp-btn-shine-effect"
        element_style={true}
      />
      <FooterSeven />
    </Wrapper>
  );
};

export default DigitalMarketing;
