import HomeStartUpAbout from "components/abouts/home-stratup-about";
import AboutCta from "components/cta/about-cta";
import StartUpFaqs from "components/faqs/start-up-faqs";
import StartupFeatures from "components/features/startup-features";
import HomeStartupBanner from "components/hero-banners/home-startup-banner";
import SEO from "components/seo";
import StartUpSupport from "components/supports/start-up-support";
import ServicesVision from "components/visions/services-vision";
import FooterSeven from "layout/footers/footer-7";
import HeaderSix from "layout/headers/header-6";
import Wrapper from "layout/wrapper";
import React from "react";
import { Link } from "react-router-dom";
import CustomPortfolio from "./custom-portfolio";
import FreelancerCta from "components/cta/freelancer-cta";
import AgencyCta from "components/cta/agency-cta";
import AboutFaqs from "components/abouts/about-page-com/about/about-faqs";
import HomePortFolio from "data/home-portfolio";

const Anouncement = () => {
  return (
    <>
      <div className="row justify-content-center mb-2">
        <div className="col-xxl-10 col-xl-10 col-lg-11">
          <div
            className="features__offer text-center mt-20 wow fadeInUp"
            data-wow-delay=".9s"
            data-wow-duration="1s"
          >
            <span>Coming Soon</span>
            <p>
              {" "}
              {/* If you want to join the effort and help with the plugin, please{" "} */}
              Download
              <Link to="/3d-space-announcement"> free 3D assets </Link>
              from our upcoming modeling and animation library.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

const HomeStartUp = () => {
  return (
    <>
      <Wrapper>
        <SEO
          metaTitle={"Rionick- 3D Modeling & Animation services | NFT Arts"}
          metaDesc={
            "We provide best-in-class 3D services like 3D Modeling, 3D Animation, NFT Art, Website Development services and Digital Marketing services."
          }
          metaKeywords={
            "3d modeling services, 3d animation, nft art, top digital marketing services, web development services"
          }
          canonical="https://www.rionick.com"
          pageTitle={"Rionick Studio | Home"}
        />
        <HeaderSix />
        <HomeStartupBanner />
        {/* <StartupFeatures /> */}
        <HomeStartUpAbout />
        <Anouncement />
        <ServicesVision />
        <AgencyCta
          content="LET’S TALK ABOUT YOUR NEXT PROJECT"
          title="Looking for customized 3D Models at scale?"
          btext="Get In Touch"
          bclr="tp-btnr bg-danger tp-btn-shine-effect"
          element_style={true}
        />
        <CustomPortfolio data={HomePortFolio} />
        <StartUpSupport />
        <AboutFaqs
          element_style={true}
          head={"Stay Tuned for our"}
          title={"3D Modeling and 3D Animation Library Coming Soon"}
          desc={
            "We will provide our users with high-quality free 3D models, textures, and Rigged Characters and Props for artists, designers, game developers, and other professionals who work with 3D assets. Our 3D files come in various formats, including modeling, texturing, rigging, and animation. Users can simply choose the format they want and download them. It will help users to save time and effort in creating their own 3D models from scratch."
          }
        />
        <StartUpFaqs />
        <FooterSeven />
      </Wrapper>
    </>
  );
};

export default HomeStartUp;
