import SEO from "components/seo";
import ContactArea from "components/contacts/contact-area";
import ElementsCtaMain from "elements/elements-cta";
import ElementsFormMain from "elements/elements-form";
import Wrapper from "layout/wrapper";
import Header from "layout/headers/header";
import FooterSeven from "layout/footers/footer-7";
import HeaderSix from "layout/headers/header-6";

export default function Contact() {
  return (
    <Wrapper>
      <SEO
        metaTitle={"Rionick-Contact Us"}
        metaDesc={
          "Contact us for 2D/3D Art services, NFT art services, Website Development services & Digital Marketing services."
        }
        metaKeywords={
          "about 3d modeling services, what is 3d animation, top class nft art, top digital marketing services, best website development services"
        }
        canonical="https://www.rionick.com/contact-us"
        pageTitle={"Contact"}
      />
      <HeaderSix top_bar={false} header_solid={true} />
      {/* <ContactArea/>x */}
      <ElementsFormMain />
      {/* <ElementsCtaMain/> */}
      <FooterSeven />
    </Wrapper>
  );
}
