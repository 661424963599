import React from "react";
import ReactDOM from "react-dom/client";
// import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomeStartUp from "./pages";
import AboutMe from "pages/about";
import ErrorPage from "pages/404";
import Contact from "pages/contact";
import Services from "pages/services";
import ComingSoon from "pages/ComingSoon";
import { Careers } from "pages/Careers";
import Services2D from "pages/Service2d";
import NFT from "pages/NFT";
import WebDevelopment from "pages/WebDevelopment";
import DigitalMarketing from "pages/DigitalMarketing";
import Policy from "pages/Policy";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<HomeStartUp />}></Route>
        <Route exact path="/about-us" element={<AboutMe />}></Route>
        <Route exact path="/contact-us" element={<Contact />}></Route>
        <Route
          exact
          path="/best-3d-services-india"
          element={<Services />}
        ></Route>
        <Route
          exact
          path="/graphic-designing-services-india"
          element={<Services2D />}
        ></Route>
        <Route
          exact
          path="/non-fungible-token-development-services-india"
          element={<NFT />}
        ></Route>
        <Route
          exact
          path="/best-digital-marketing-services-india"
          element={<DigitalMarketing />}
        ></Route>
        <Route
          exact
          path="/best-web-development-services-india"
          element={<WebDevelopment />}
        ></Route>
        <Route
          exact
          path="/3d-space-announcement"
          element={<ComingSoon />}
        ></Route>
        <Route exact path="/Careers" element={<Careers />}></Route>
        <Route exact path="/privacy-policy" element={<Policy />}></Route>

        <Route path="*" element={<ErrorPage />}></Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
