import React from "react";
// internal
import title_icon from "assets/img/about/about-me-title-icon.png";
import { ArrowRightThree } from "svg/index";
import { Image } from "react-bootstrap";

const AboutMeInfo = () => {
  return (
    <section className="about__me-info pb-90 pt-110">
      <div className="container">
        <div className="row">
          <div className="col-xl-4 col-lg-3">
            <span className="about__me-info-subtitle">ABOUT US</span>
          </div>
          <div className="col-xl-8 col-lg-9">
            <div
              className="about__me-info-content wow fadeInUp"
              data-wow-delay=".3s"
              data-wow-duration="1s"
            >
              <h4 className="about__me-info-title">
                Best 2D/3D art, NFT, and Digital Marketing services{" "}
                <Image src={title_icon} alt="title icon" />
              </h4>
              <p>
                Rionick specializes in 2D services and 3D services of any
                complexity, smooth animations, and assets suited for various
                industries.
              </p>

              <p>
                We make top-notch NFT art creations for multiple platforms and
                purposes. Our creative professionals are laser-focused on
                developing top-quality content to meet clients and industry
                standards. Whether it's website development or digital
                marketing, our creations are tailored to satisfy our clients'
                requirements fully.
              </p>

              <p>
                Furthermore, our team of talented experts from various
                industries possess extensive experience and knowledge that helps
                us meet and exceed your expectations.
              </p>

              {/* <div className="about__me-info-bottom d-sm-flex align-items-center mt-40">
                <div className="about__me-info-btn mr-30">
                  <a
                    rel="noreferrer"
                    href="https://weblearnbd.net/wp/bioxlab/wp-content/uploads/2022/09/demo-cv.pdf"
                    target="_blank"
                    className="tp-btn"
                  >
                    Download CV
                    <ArrowRightThree />
                  </a>
                </div>
                <div className="about__me-info-social">
                  <a href="#">
                    <i className="fa-brands fa-facebook-f"></i> Facebook
                  </a>
                  <a href="#">
                    <i className="fa-brands fa-twitter"></i> Twitter
                  </a>
                  <a href="#">
                    <i className="fa-brands fa-linkedin-in"></i> LinkedIn
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutMeInfo;
