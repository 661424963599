import { Link } from 'react-router-dom';
import React from "react";

const CopyrightText = () => {
  return (
    <p>
      Copyright © {new Date().getFullYear()} by <Link href="/">Rionick</Link> {' '}
      All rights reserved.
    </p>
  );
};

export default CopyrightText;
