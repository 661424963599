import React from "react";

const PolicyArea = () => {
  return (
    <section className="policy__area pb-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <div className="policy__wrapper policy__translate p-relative z-index-1">
              <div className="policy__item mb-35">
                <h4 className="policy__meta">Effective date: May 25, 2023</h4>
                <p>
                  The information provided by Rionick Studios (“Company”, “we”, “our”, “us”) on rionick.com (the “Site”) is for general informational purposes only. All information on the Site is provided in good faith. However, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Site.

                  UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE SITE OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE. YOUR USE OF THE SITE AND YOUR RELIANCE ON ANY INFORMATION ON THE SITE IS SOLELY AT YOUR OWN RISK.

                </p>
                {/* <p>
                  By using or accessing the Services in any manner, you
                  acknowledge that you accept the practices and policies
                  outlined in this Privacy Policy, and you hereby consent that
                  Harry will collect, use, and share your information in the
                  following ways. Remember that your use of Services is at all
                  times subject to the Terms as set forth at
                  https://harry.com/legal/terms, which incorporate this Privacy
                  Policy. Any terms Harry uses in this Privacy Policy without
                  defining them have the definitions given to them in the Terms.
                </p> */}
              </div>

              <div className="policy__item policy__item-2 mb-35">
                <h3 className="policy__title">EXTERNAL LINKS DISCLAIMER</h3>
                <p>
                  The Site may contain (or you may be sent through the Site) links to other websites or content belonging to or originating from third parties or links to websites and features. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability or completeness by us.
                </p>
                <p>
                  For example, the outlined Disclaimer has been created using PolicyMaker.io, a free web application for generating high-quality legal documents. PolicyMaker’s disclaimer generator is an easy-to-use tool for creating an excellent sample Disclaimer template for a website, blog, eCommerce store or app.
                </p>

                <p>
                  WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF ANY INFORMATION OFFERED BY THIRD-PARTY WEBSITES LINKED THROUGH THE SITE OR ANY WEBSITE OR FEATURE LINKED IN ANY BANNER OR OTHER ADVERTISING. WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.
                </p>

              </div>

              <div className="policy__list mb-35">
                <h3 className="policy__title">AFFILIATES DISCLAIMER</h3>
                <p>The Site may contain links to affiliate websites, and we may receive an affiliate commission for any purchases or actions made by you on the affiliate websites using such links.</p>
                {/* <ul>
                  <li>The Site may contain links to affiliate websites, and we may receive an affiliate commission for any purchases or actions made by you on the affiliate websites using such links.</li>
                  <li>To detect, prevent and address technical issues;</li>
                  <li>
                    To register you as a user and to provide the services you
                    require;
                  </li>
                  <li>
                    To decide whether to offer you a Capchase product or
                    service;
                  </li>
                  <li>To notify you about changes to our Service;</li>
                  <li>To provide customer care and support;</li>
                </ul> */}
              </div>

              <div className="policy__list mb-35">
                <h3 className="policy__title">TESTIMONIALS DISCLAIMER</h3>
                <p>The Site may contain testimonials by users of our products and/or services. These testimonials reflect the real-life experiences and opinions of such users. However, the experiences are personal to those particular users and may not necessarily be representative of all users of our products and/or services. We do not claim, and you should not assume that all users will have the same experiences.</p>
              </div>
              <div className="policy__list mb-35">
                <h3 className="policy__title">YOUR INDIVIDUAL RESULTS MAY VARY.</h3>
                <p>The testimonials on the Site are submitted in various forms such as text, audio and/or video, and are reviewed by us before being posted. They appear on the Site verbatim as given by the users, except for the correction of grammar or typing errors. Some testimonials may have been shortened for the sake of brevity, where the full testimonial contained extraneous information not relevant to the general public.</p>
                <p>The views and opinions contained in the testimonials belong solely to the individual user and do not reflect our views and opinions.
                </p>
              </div>
              <div className="policy__list mb-35">
                <h3 className="policy__title">ERRORS AND OMISSIONS DISCLAIMER</h3>
                <p>While we have made every attempt to ensure that the information contained in this site has been obtained from reliable sources, Rionick Studios is not responsible for any errors or omissions or for the results obtained from the use of this information. All information in this site is provided “as is”, with no guarantee of completeness, accuracy, timeliness or of the results obtained from the use of this information, and without warranty of any kind, express or implied, including, but not limited to warranties of performance, merchantability, and fitness for a particular purpose.</p>
                <p>In no event will Rionick Studios, its related partnerships or corporations, or the partners, agents or employees thereof be liable to you or anyone else for any decision made or action taken in reliance on the information in this Site or for any consequential, special or similar damages, even if advised of the possibility of such damages.
                </p>
              </div>
              <div className="policy__list mb-35">
                <h3 className="policy__title">LOGOS AND TRADEMARKS DISCLAIMER</h3>
                <p>All logos and trademarks of third parties referenced on rionick.com are the trademarks and logos of their respective owners. Any inclusion of such trademarks or logos does not imply or constitute any approval, endorsement or sponsorship of Rionick Studios by such owners.
                </p>
              </div>
              <div className="policy__contact">
                <h3 className="policy__title policy__title-2">Contact us</h3>
                <p>Should you have any feedback, comments, requests for technical support or other inquiries, please contact us by email:</p>

                <ul>
                  <li>
                    Email:{" "}
                    <span>
                      <a href="mailto:contact@rionick.com">contact@rionick.com</a>
                    </span>
                  </li>
                </ul>

                {/* <div className="policy__address">
                  <p>
                    <a
                      rel="noreferrer"
                      href="https://www.google.com/maps/place/102+Thompson+St,+New+York,+NY+10012,+USA/@40.7255437,-74.004061,17z/data=!3m1!4b1!4m5!3m4!1s0x89c2598c338f691d:0x6d5f394e7a1962cc!8m2!3d40.7255397!4d-74.0018723"
                      target="_blank"
                    >
                      harry PTY LTD <br /> 4270, Brisbane, Australia
                    </a>
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PolicyArea;
