import ServiceThreeAbout from "components/abouts/service-3-about";
import AgencyBrands from "components/brands/agency-brands";
import AgencyCta from "components/cta/agency-cta";
import ServiceThreeCta from "components/cta/service-3-cta";
import CommonFaq from "components/faqs/common-faq";
import ServiceThreeFeature from "components/features/service-3-feature";
import { PortfolioMasonryArea } from "components/portfolios/portfolio-page-com/portfolio-masonry";
// import { PortfolioMasonryArea } from "components/portfolios/portfolio-page-com/portfolio-masonry";
import SEO from "components/seo";
import ServiceThreeServices from "components/services/service-3-services";
import ServiceThreeTestimonial from "components/testimonials/service-3-testimonial";
import ServiceThreeVideoArea from "components/video-area/service-3-video-area";
import SectionWrapper from "elements/section-wrapper";
import FooterSeven from "layout/footers/footer-7";
import Header from "layout/headers/header";
import HeaderSix from "layout/headers/header-6";
import Wrapper from "layout/wrapper";
import CalenderThree from "svg/calender-3";
import CommentBigTwo from "svg/comment-big-2";
import MobileAppThree from "svg/mobile-app-3";
import SeoOptimize from "svg/seo-optimize";
import logo from "assets/img/logo/logo-black.svg";

// Banner Image

import bannericon2 from "assets/img/Main Images/2D/rionickicon.png"
import banner2d from "assets/img/Main Images/2D/video-bg-2.jpg"

//2D Service Des
import service1 from "assets/img/Main Images/2D/Top class logo design services.jpg";
import service2 from "assets/img/Main Images/2D/Graphic designing services in India.jpg";

// 2D Portfolio
import gd_portfolio1 from "assets/img/Main Images/2D/Dog clinic logo.jpg"
import gd_portfolio2 from "assets/img/Main Images/2D/Amber concept art.jpg"
import gd_portfolio3 from "assets/img/Main Images/2D/Thumbs up sticker.jpg"
import gd_portfolio4 from "assets/img/Main Images/2D/Salon logo design.jpg"
import gd_portfolio5 from "assets/img/Main Images/2D/2D Game User interface.jpg"
import gd_portfolio6 from "assets/img/Main Images/2D/User interface of game.jpg"
import gd_portfolio7 from "assets/img/Main Images/2D/Sports teams logo.jpg"
import gd_portfolio8 from "assets/img/Main Images/2D/Witch concept art.jpg"
import gd_portfolio9 from "assets/img/Main Images/2D/Social media sticker.jpg"
import gd_portfolio10 from "assets/img/Main Images/2D/Business card.jpg"


const portfoliio = [
  // APPS
  {
    id: '1',
    img: gd_portfolio1,
    main_img: gd_portfolio1,
    title: 'Magazine Cover',
    category: 'Mockup'
  },
  {
    id: '2',
    img: gd_portfolio2,
    main_img: gd_portfolio2,
    title: 'Logo Mockups',
    category: 'Apps'
  },
  {
    id: '3',
    img: gd_portfolio3,
    main_img: gd_portfolio3,
    title: 'Logo Mockups',
    category: 'Apps'
  },
  {
    id: '4',
    img: gd_portfolio4,
    main_img: gd_portfolio4,
    title: 'Logo Mockups',
    category: 'Apps'
  },
  {
    id: '5',
    img: gd_portfolio5,
    main_img: gd_portfolio5,
    title: 'Logo Mockups',
    category: 'Apps'
  },
  {
    id: '6',
    img: gd_portfolio6,
    main_img: gd_portfolio6,
    title: 'Logo Mockups',
    category: 'Apps'
  },
  {
    id: '7',
    img: gd_portfolio7,
    main_img: gd_portfolio7,
    title: 'Logo Mockups',
    category: 'Apps'
  },
  {
    id: '8',
    img: gd_portfolio8,
    main_img: gd_portfolio8,
    title: 'Logo Mockups',
    category: 'Apps'
  },
  {
    id: '9',
    img: gd_portfolio9,
    main_img: gd_portfolio9,
    title: 'Logo Mockups',
    category: 'Apps'
  },
  {
    id: '10',
    img: gd_portfolio10,
    main_img: gd_portfolio10,
    title: 'Logo Mockups',
    category: 'Apps'
  },
]

const ListDevelopment = [
  {
    icon: <i class="fi fi-rs-vector-polygon"></i>,
    title: "Logo design",
    subtitle:
      "We offer custom logo designs that are unique and innovative and helps communicate your brand’s identity to your ideal customers.",
  },
  {
    icon: <i class="fi fi-rr-share"></i>,
    title: "Social media graphics",
    subtitle:
      "With our eye-catching visuals we create outstanding social media graphics that help your bring your ideas to life.",
  },
  {
    icon: <i class="fi fi-rr-edit"></i>,
    title: "Banner design",
    subtitle:
      "We provide banner design services across various industries. Our expert design banners with the motive of giving a voice to your brand.",
  },
  {
    icon: <i class="fi fi-rr-file"></i>,
    title: "Brochures",
    subtitle:
      "We craft brochures with attractive designs that capture the audiences’ attention and create a feeling of trust in them.",
  },
];
export default function Services2D() {
  return (
    <Wrapper>
      <SEO
        metaTitle={
          "Graphic Designing Agency | Graphic Design Services in Delhi NCR"
        }
        metaDesc={
          "From graphic designing to motion graphics and video presentation, we provide best graphic designs in Delhi NCR."
        }
        metaKeywords={
          "best graphic design, graphic design services in delhi ncr, graphic design services online, affordable graphic designing services, motion graphics, social media graphics, logo design cost"
        }
        canonical="https://www.rionick.com/graphic-designing-services-india"
        pageTitle={"Graphic Design"}
      />
      <HeaderSix top_bar={false} commonOffCanvas={true} />
      <ServiceThreeVideoArea
        banner_icon= {bannericon2}
        video_bg={banner2d}
        t2="Transform your business with our custom graphic design services"
        t1="We are here for you."
      />
      {/* <ServiceThreeFeature/> */}
      <ServiceThreeServices list={ListDevelopment} />
      <ServiceThreeAbout
        t1="Do you provide customized graphic designing services?"
        p="Yes,we have a team of expert graphic designers who specialize in providing customized graphics according to the needs of our clients. Our team excels in offering visually appealing and eye-catching graphics."
        about_img={service1}
        reverse={true}

      />
      <ServiceThreeAbout
        t1="What other services do you provide?"
        p="Apart from above mentioned services we provide:"
        list={[
          "Flyer design",
          "Business card design",
          "Poster design",
          "Catalogue design",
          "Restaurant menu design",
          "Letterhead design",
        ]}
        about_img={service2}
        reverse={false}
      />
      <AgencyCta
        content="Get custom designs"
        btext="Get in touch"
        bclr="tp-btnr bg-danger tp-btn-shine-effect"
        title="High-quality graphic designing services at affordable rates"
        element_style={true}
      />
      <PortfolioMasonryArea data={portfoliio} />
      {/* <ServiceThreeTestimonial/>
      <AgencyBrands spacing={true} />
      <CommonFaq />
      <ServiceThreeCta /> */}

      <FooterSeven />
    </Wrapper>
  );
}
