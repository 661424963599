import React, { lazy, useState } from "react";
// import dynamic from "next/dynamic";
import { Image } from "react-bootstrap";
// import ReactTooltip from "react-tooltip";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import useModal from "hooks/use-modal";
import Plus from "svg/plus";
import PlayThree from "svg/play-3";
import ArrowRightTwo from "svg/arrow-right-2";
import VideoModal from "components/common/modals/modal-video";
import ImageLightBox from "components/common/modals/image-lightbox";

export const MasonrySinglePort = ({ item, images, index, tooltip_hover = true }) => {
  const { id, img, title, category, video, video_id } = item || {};

  const { isVideoOpen, setIsVideoOpen } = useModal();
  // photoIndex
  const [photoIndex, setPhotoIndex] = useState(null);
  // image open state
  const [open, setOpen] = useState(false);
  const handleImagePopup = (i) => {
    setPhotoIndex(i);
    setOpen(true);
  };
  return (
    <React.Fragment>
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className="portfolio__grid-item mb-20"
        data-tip
        data-for={id}
      >
        <div
          className={`portfolio__grid-thumb w-img fix ${tooltip_hover ? "tp-img-reveal tp-img-reveal-item" : ""
            }`}
          data-tip
          data-for={tooltip_hover ? id : ''}
        >
          <Link href="/portfolio-details">
            <Image
              src={img}
              alt="img"
              style={{ width: "100%", height: "100%" }}
            />
          </Link>
          <div className={`portfolio__grid-${video ? "video" : "popup"}`}>
            {!video && (
              <a
                style={{ cursor: "pointer" }}
                onClick={() => handleImagePopup(index)}
                className="popup-image"
              >
                <Plus />
              </a>
            )}
            {video && (
              <a
                style={{ cursor: "pointer" }}
                onClick={() => setIsVideoOpen(true)}
                className="portfolio-play-btn popup-video"
                
              >
                <PlayThree />
              </a>
            )}
          </div>
        </div>
        {!tooltip_hover && (
          <div className="portfolio__grid-content">
            <h3 className="portfolio__grid-title">
              <Link href="/portfolio-details">{title}</Link>
            </h3>
            <div className="portfolio__grid-bottom">
              <div className="portfolio__grid-category">
                <span>
                  <a href="#">{category}</a>
                </span>
              </div>
              <div className="portfolio__grid-show-project">
                <Link href="/portfolio-details" className="portfolio-link-btn">
                  Show project
                  <span>
                    <ArrowRightTwo />
                  </span>
                </Link>
              </div>
            </div>
          </div>
        )}
        {tooltip_hover && (
          <ReactTooltip id={id} type="light">
            <div className="portfolio__grid-content portfolio__hover-category">
              <h3 className="portfolio__grid-title">
                <Link href="/portfolio-details">{title}</Link>
              </h3>
              <span>
                <a href="#">{category}</a>
              </span>
            </div>
          </ReactTooltip>
        )}
        {/* <div className="portfolio__grid-thumb w-img fix tp-img-reveal tp-img-reveal-item">
          <Link href="/portfolio-details-list">
            <Image
              src={img}
              alt="image"
              style={{ width: "100%", height: "100%" }}
            />
          </Link>
        </div> */}
      </motion.div>
      <VideoModal
        isVideoOpen={isVideoOpen}
        setIsVideoOpen={setIsVideoOpen}
        videoId={video_id}
      />
      {/* video modal end */}

      {/* image light box start */}
      <ImageLightBox
        images={images}
        open={open}
        setOpen={setOpen}
        photoIndex={photoIndex}
        setPhotoIndex={setPhotoIndex}
      />
      {/* <ReactTooltip id={id} type="dark">
        <div className="portfolio__grid-content portfolio__hover-category">
          <h3 className="portfolio__grid-title">
            <Link href="/portfolio-details">{title}</Link>
          </h3>
          <span>
            <a href="#">{category}</a>
          </span>
        </div>
      </ReactTooltip> */}
    </React.Fragment>
  );
};

// const LazyComponent = lazy(() => import(MasonrySinglePort));
// export default lazy(() => Promise.resolve(MasonrySinglePort), {
//   ssr: false,
// });
