import AboutCta from "components/cta/about-cta";
import AgencyCta from "components/cta/agency-cta";
import JobPositionArea from "components/job-positions/job-position-area";
import JobSectionTitle from "components/job-positions/job-section-title";
import JobThumbArea from "components/job-positions/job-thumb-area";
import SEO from "components/seo";
import FooterSeven from "layout/footers/footer-7";
import HeaderSix from "layout/headers/header-6";
import Wrapper from "layout/wrapper";
import React from "react";
import thumb_img from "assets/img/job/job-img-1.jpg";

export const Careers = () => {
  return (
    <Wrapper>
      <SEO pageTitle={"Job"} />
      <HeaderSix
      // top_bar={false}
      // header_black={true}
      // header_solid={true}
      // commonOffCanvas={true}
      />
      <JobSectionTitle />
      <JobThumbArea thumb_img={thumb_img} />
      {/* <JobPositionArea style_2={true} /> */}
      <AgencyCta
        title="Tap for future alerts!"
        content=""
        btext="Let's Connect"
        bclr="tp-btnr bg-danger tp-btn-shine-effect"
        element_style={true}
      />
      <FooterSeven />
    </Wrapper>
  );
};
