import React from "react";

import { Link } from 'react-router-dom';
// internal
import logo from "assets/img/logo/logo-white.png";
import bg_shape from "assets/img/footer/footer-shape-2.jpg";
import { LocationTwo, Call, Email } from "svg/index";
import SocialLinks from "components/social";
import CopyrightText from "./component/copyright-text";
import { Image } from "react-bootstrap";

// footer widget
function FooterWidget({ col, col_2, title, contents }) {
  return (
    <div className={`col-xxl-${col} col-xl-${col} col-lg-3 col-md-6 col-sm-6`}>
      <div className={`footer__widget-6 mb-50 footer-col-6-${col_2}`}>
        <h3 className="footer__widget-title-6">{title}</h3>
        <div className="footer__widget-content">
          <ul>
            {contents.map((item, i) => (
              <li key={i}>
                <Link to={item.url}>{item.title}</Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

const FooterSeven = () => {
  return (
    <>
      <footer>
        <div
          className="footer__area p-relative z-index-1"
          data-bg-color="footer-bg-blue"
        >
          <div
            className="footer__bg"
            style={{ backgroundImage: `url(${bg_shape})` }}
          ></div>
          <div className="footer__top-6">
            <div className="container">
              <div className="row">
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                  <div className="footer__widget-6 mb-50 footer-col-6-1">
                    <div className="footer__logo">
                      <Link to="/">
                        <Image src={logo} alt="logo" />
                      </Link>
                    </div>

                    <div className="footer__widget-content">
                      <div className="footer__info text-white">
                        <p>Our creativity begins where your imagination ends</p>
                        {/* <span>
                          <a href="tel:918377193542">
                            <i className="fa-solid fa-phone"></i>{" "}
                            +918377193542
                          </a>
                        </span>
                        <div className="footer__contact-mail text-white">
                          <span>
                            <a href="mailto:contact@rionick.com">
                              contact@rionick.com
                            </a>
                          </span>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <FooterWidget
                  col="3"
                  col_2="2"
                  title="Quick Links"
                  contents={[
                    { url: "/", title: "Home" },
                    // { url: "/about-us", title: "About" },
                    { url: "/best-3d-services-india", title: "3D Services" },
                    { url: "/graphic-designing-services-india", title: "2D Services" },
                    { url: "/non-fungible-token-development-services-india", title: "NFT" },
                    { url: "/best-digital-marketing-services-india", title: "Digital Marketing" },
                    { url: "/best-web-development-services-india", title: "Web Development" },
                  ]}
                />
                <FooterWidget
                  col="2"
                  col_2="3"
                  title="Resources"
                  contents={[
                    { url: "/Careers", title: "Careers" },
                    { url: "/3d-space-announcement", title: "3D Space" },
                    // { url: "/contact-us", title: "Contact Us" },
                  ]}
                />
                <div className="col-xxl-4 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                  <div className="footer__widget-6 mb-50 footer-col-6-4">
                    <h3 className="footer__widget-title-6">Contact Us</h3>

                    <div className="footer__widget-content">
                      <div className="footer__info">
                        <div className="footer__info-item-6 d-flex align-items-start">
                          {/* <div className="footer__info-icon-6 mr-20"> */}
                          {/* <span>
                              <LocationTwo />
                            </span> */}
                          {/* </div> */}
                          {/* <div className="footer__info-text-6">
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href="https://www.google.com/maps/place/Orville+St,+La+Presa,+CA+91977,+USA/32.7092048,-117.0082772,17z/data=!3m1!4b1!4m5!3m4!1s0x80d9508a9aec8cd1:0x72d1ac1c9527b705!8m2!3d32.7092003!4d-117.0060885"
                            >
                              711-2880 Nulla St.
                            </a>
                          </div> */}
                        </div>
                        <div className="footer__info-item-6 d-flex align-items-start">
                          <div className="footer__info-icon-6 mr-20">
                            <span>
                              <Email />
                            </span>
                          </div>
                          <div className="footer__info-text-6">
                            <a href="mailto:contact@rionick.com">
                              contact@rionick.com
                            </a>
                          </div>
                        </div>
                        <div className="footer__info-item-6 d-flex align-items-start">
                          <div className="footer__info-icon-6 mr-20">
                            <span>
                              <Call />
                            </span>
                          </div>
                          <div className="footer__info-text-6">
                            <a href="tel:918377935429">+918377935429</a>
                          </div>
                        </div>
                      </div>
                      <div className="footer__social-6">
                        <SocialLinks />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer__bottom">
            <div className="container">
              <div className="footer__bottom-inner-6">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="footer__copyright-6">
                      <CopyrightText />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="footer__link-6 text-sm-end">
                      <Link to="/privacy-policy">Privacy Policy</Link>
                      {/* <Link href="/terms">Terms of Use</Link>
                      <Link href="/policy">Cookie Policy</Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterSeven;
