import AboutMeArea from "components/abouts/about-page-com/about-me";
import SEO from "components/seo";
import FooterSeven from "layout/footers/footer-7";
import Header from "layout/headers/header";
import HeaderSix from "layout/headers/header-6";
import Wrapper from "layout/wrapper";

export default function AboutMe() {
  return (
    <Wrapper>
      <SEO
        metaTitle={"Rionick-About Us"}
        metaDesc={
          "We specialize in 2D/3D Art, NFT, Website Development  & Digital Marketing."
        }
        metaKeywords={
          "about 3d modeling services, what is 3d animation, top class nft art, top digital marketing services, best website development services"
        }
        canonical="https://www.rionick.com/about-us"
        pageTitle={"About US"}
      />
      <HeaderSix top_bar={false} commonOffCanvas={true} />
      <AboutMeArea />
      <FooterSeven style_2={true} />
    </Wrapper>
  );
}
