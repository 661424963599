import React from 'react';
// internal
import AboutMeTestimonial from 'components/testimonials/about-me-testimonial';
import PortfolioService from 'components/services/portfolio-service';
import PortfolioSkills from 'components/skills/portfolio-skills';
import AgencyBrands from 'components/brands/agency-brands';
import AboutCta from 'components/cta/about-cta';
import AboutMeBreadcrumb from './about-me-breadcrumb';
import AboutMeInfo from './about-me-info';
import AboutMePortfolio from './about-me-portfolio';

// About Page Iconsnpm i @emailjs/browser --legacy-peer-deps

import icon1 from "assets/img/Main Images/About/company-icon-1.png"
import icon2 from "assets/img/Main Images/About/company-icon-2.png"
import icon3 from "assets/img/Main Images/About/company-icon-3.png"
import icon4 from "assets/img/Main Images/About/company-icon-4.png"

const data = [
  {
    icon: icon1,
    image: true,
    title: " Strong relationships:",
    designation:
      "We believe in delivering the best 3d modeling services to build good relationships with our clients.",
    // year: "April 2020 - Present",
  },
  {
    icon: icon2,
    image: true,
    title: " Premium quality:",
    designation:
      "Our 3d artist creates incredibly high quality 3d modeling and animation services to meet all the necessary criteria set by our clients.",
    // year: "April 2016 - March 2020",
  },
  {
    icon: icon3,
    image: true,
    title: "Team work:",
    designation:
      "We have a team of 3d artists and modelers to work together to deliver the best 3d modeling services in India.",
    // year: "April 2012 - March 2016",
  },
  {
    icon: icon4,
    image: true,
    title: "Creativity:",
    designation:
      "We generate innovative and creative ideas for designing unique 3D models and captivating digital artwork.",
    // year: "April 2008 - March 2012",
  },
];

const AboutMeArea = () => {
  return (
    <React.Fragment>
      <AboutMeBreadcrumb />
      <AboutMeInfo />
      {/* <PortfolioService style_2={true} /> */}
      <PortfolioSkills data={data} />
      {/* <AboutMePortfolio/> */}
      {/* <AgencyBrands spacing={true} style_2={true} /> */}
      <AboutMeTestimonial />
      {/* <AboutCta/> */}
    </React.Fragment>
  );
};

export default AboutMeArea;