import ServiceThreeAbout from "components/abouts/service-3-about";
import AgencyCta from "components/cta/agency-cta";
import SEO from "components/seo";
import ServiceThreeServices from "components/services/service-3-services";
import ServiceThreeVideoArea from "components/video-area/service-3-video-area";
import FooterSeven from "layout/footers/footer-7";
import HeaderSix from "layout/headers/header-6";
import Wrapper from "layout/wrapper";
import React from "react";
import CalenderThree from "svg/calender-3";
import CommentBigTwo from "svg/comment-big-2";
import MobileAppThree from "svg/mobile-app-3";
import SeoOptimize from "svg/seo-optimize";

// Banner Image

import bannericonweb from "assets/img/Main Images/Web Dev/rionickicon.png"
import bannerwd from "assets/img/Main Images/Web Dev/video-bg-2.jpg"

// Website Dev Service Images

import WDService1 from "assets/img/Main Images/Web Dev/Best website designing services.jpg"
import WDService2 from "assets/img/Main Images/Web Dev/Web development services in India.jpg"

const ListDevelopment = [
  {
    icon: <MobileAppThree />,
    title: "UI and UX Design",
    subtitle:
      "Our designers create visually attractive and customized user-friendly interfaces for websites, mobile apps, and other human-machine interfaces.",
  },
  {
    icon: <SeoOptimize />,
    title: "Web Development",
    subtitle:
      "We build websites starting from basic website designs, including CMS and online store building to highly complex business website apps for your business.",
  },
  {
    icon: <CalenderThree />,
    title: "Mobile App Development",
    subtitle:
      "We offer Intuitive designs combined with compelling user experience for iOS and Android devices. We build mobile apps that integrate all mobile platforms with the latest technologies.",
  },
  {
    icon: <CommentBigTwo />,
    title: "Support and Maintenance",
    subtitle:
      "We provide comprehensive technical support for your software, including app delivery, deployment, enhancements, and ongoing maintenance services.",
  },
];
const WebDevelopment = () => {
  return (
    <Wrapper>
      <SEO
        metaTitle={
          "Best Web Development Company in India | Web Design in Delhi, Noida"
        }
        metaDesc={
          "We offer the best website development, UX and UI design in delhi ncr, back-end and front-end services and continuous support and integration of advanced tech modules."
        }
        metaKeywords={
          "best web development in India, web development company, website designing cost, front-end development, back-end development, ux and ui design"
        }
        canonical="https://www.rionick.com/best-web-development-services-india"
        pageTitle={"WebDevelopment"}
      />
      <HeaderSix top_bar={false} commonOffCanvas={true} />
      <ServiceThreeVideoArea
        banner_icon= {bannericonweb}
        video_bg={bannerwd}
        t1="We are here for you."
        t2="Providing end-to-end Website Development Services"
      />
      {/* <ServiceThreeFeature/> */}
      <ServiceThreeServices list={ListDevelopment} />
      <ServiceThreeAbout
        t1="What are the tools and technologies used in web development?"
        p="During the website development process, different programming languages are used, which can be categorized into frontend and backend. Front-end languages include React.js, JavaScript, HTML, CSS, and Sass, while backend languages encompass Node.js, PHP, and Python."
        reverse={true}
        about_img={WDService1}

      />
      <ServiceThreeAbout
        t1="How can we cater your web designing needs?"
        p="With our end-to-end web development services, we offer Website design, UX and UI design, an ecommerce website and continuous support and evolution, including the development and integration of advanced tech modules."
        reverse={false}
        about_img={WDService2}

      />
      <AgencyCta
        content="Want our help?"
        title="Accelerate your web development needs with our services"
        btext="Get in touch"
        bclr="tp-btnr bg-danger tp-btn-shine-effect"
        element_style={true}
      />
      {/* <PortfolioMasonryArea /> */}
      {/* <ServiceThreeTestimonial/>
    <AgencyBrands spacing={true} />
    <CommonFaq />
    <ServiceThreeCta /> */}

      <FooterSeven />
    </Wrapper>
  );
};

export default WebDevelopment;
