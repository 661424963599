// // external
// import { Link } from "react-router-dom";
// // internal
// import { FooterSeven, Header, Wrapper } from "layout/index";
// import SEO from "components/seo";

import SEO from "components/seo";
import FooterSeven from "layout/footers/footer-7";
import Header from "layout/headers/header";
import HeaderSix from "layout/headers/header-6";
import Wrapper from "layout/wrapper";
import { Link } from "react-router-dom";
import ArrowRightFive from "svg/arrow-right-5";
import ErrorSvg from "svg/error-svg";

// import { ArrowRightFive, ErrorSvg } from "svg/index";

export default function ErrorPage() {
  return (
    <Wrapper>
      <SEO pageTitle={"Error Page"} />
      <HeaderSix top_bar={false} header_solid={true} commonOffCanvas={true} />
      {/* error area start */}
      <section className="error__area error__bg pb-110">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8 col-md-10">
              <div className="error__wrapper p-relative z-index-1 text-center">
                <h3 className="error__text">404</h3>
                <div className="error__thumb mb-35">
                  <ErrorSvg />
                </div>
                <div className="error__content">
                  <h3 className="error__title">Oops! Page not found</h3>
                  <p>
                    Whoops, this is embarassing. Looks like the page you were
                    looking for was not found.
                  </p>
                  <Link to="/" className="tp-btn">
                    Back to Home
                    <ArrowRightFive />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* error area end */}
      <FooterSeven style_2={true} />
    </Wrapper>
  );
}
