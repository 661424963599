// import Head from "next/head";

const SEO = ({
  pageTitle,
  font,
  metaTitle = "",
  metaDesc = "",
  metaKeywords = "",
  canonical = "",
}) => (
  <>
    <head>
      <title>{pageTitle && `${pageTitle} `}</title>
      <meta httpEquiv="x-ua-compatible" content="ie=edge" />
      {/* <meta name="description" content="Generated by create next app" /> */}
      {/* <meta name="robots" content="noindex, follow" /> */}
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />

      {font && <link href={font} rel="stylesheet" />}
      <link rel="icon" href="/favicon.ico" />
      <meta name="title" content={metaTitle} />
      <meta name="description" content={metaDesc} />
      <meta name="keywords" content={metaKeywords} />
      <meta name="robots" content="index, follow" />

      {/* (will come under head section) 

*/}
      <link rel="canonical" href={canonical} />
    </head>
  </>
);

export default SEO;
