import img_1 from 'assets/img/portfolio/grid/portfolio-grid-1.jpg';
import img_2 from 'assets/img/portfolio/grid/portfolio-grid-2.jpg';
import img_3 from 'assets/img/portfolio/grid/portfolio-grid-3.jpg';
import img_4 from 'assets/img/portfolio/grid/portfolio-grid-4.jpg';
import img_5 from 'assets/img/portfolio/grid/portfolio-grid-5.jpg';
import img_6 from 'assets/img/portfolio/grid/portfolio-grid-6.jpg';
import img_7 from 'assets/img/portfolio/grid/portfolio-grid-7.jpg';
import img_8 from 'assets/img/portfolio/grid/portfolio-grid-8.jpg';
import img_9 from 'assets/img/portfolio/grid/portfolio-grid-9.jpg';
import img_10 from 'assets/img/portfolio/grid/portfolio-grid-10.jpg';
import img_11 from 'assets/img/portfolio/grid/portfolio-grid-11.jpg';
import img_12 from 'assets/img/portfolio/grid/portfolio-grid-12.jpg';

const portfolio_classic_data = [
  // APPS
  {
    id: '1',
    img: img_1,
    main_img: img_1,
    title: 'Dog Paws Logo',
    category: 'Apps'
  },
  {
    id: '2',
    img: img_2,
    main_img: img_2,
    title: 'Mobile App UI',
    category: 'Apps'
  },
  {
    id: '3',
    img: img_3,
    main_img: img_3,
    title: 'Looks Salon',
    category: 'Apps'
  },
  // BRANDING
  {
    id: '4',
    img: img_4,
    main_img: img_4,
    title: 'Skull Canon',
    category: 'Branding'
  },
  {
    id: '5',
    img: img_5,
    main_img: img_5,
    title: 'OLD CRT TV',
    category: 'Branding'
  },
  //  Creative
  {
    id: '6',
    img: img_6,
    main_img: img_6,
    title: 'Stone Monster',
    category: 'Creative',
    video: true,
    video_id: '1gyTUHP6ne8',
  },
  {
    id: '7',
    img: img_7,
    main_img: img_7,
    title: 'Stylized Telescope',
    category: 'Creative'
  },
  {
    id: '8',
    img: img_8,
    main_img: img_8,
    title: 'UX Web Design',
    category: 'Creative',
    video: true,
    video_id: 'ty8Y0hCJIYw',
  },
  {
    id: '9',
    img: img_9,
    main_img: img_9,
    title: 'Magazine Cover',
    category: 'Creative'
  },
  // Packaging
  {
    id: '10',
    img: img_10,
    main_img: img_10,
    title: 'Visual Identity',
    category: 'Packaging'
  },
  {
    id: '11',
    img: img_11,
    main_img: img_11,
    title: 'UX Web Design',
    category: 'Packaging',
    video: true,
    video_id: 'Xm2VKy2a6aQ',
  },
  // Mockup
  {
    id: '12',
    img: img_12,
    main_img: img_12,
    title: 'Magazine Cover',
    category: 'Mockup'
  },
]

export default portfolio_classic_data;